import { UploadedFileId } from '~/src/services/FileApiService'

export type ImageDto = {
  mobile: string
  desktop: string
}

export type DesignConfigDto = Partial<{
  primaryColor: number[] | null
  secondaryColor: number[] | null
  logo: ImageDto | null
  loginBackground: ImageDto | null
  portalBackground: ImageDto | null
  loginLogoBgColor: number[] | null
  loginLogo: ImageDto | null
}>

export class DesignConfigImage {
  public readonly mobile: UploadedFileId
  public readonly desktop: UploadedFileId

  public constructor(mobile: UploadedFileId, desktop: UploadedFileId) {
    this.mobile = mobile
    this.desktop = desktop
  }
}

export type ColorTriple = [number, number, number]

export class Color {
  public readonly red
  public readonly green
  public readonly blue

  public constructor(red: number, green: number, blue: number) {
    ;[red, green, blue].forEach(function (value: number) {
      if (value >= 0 && value <= 255) {
        return
      }

      throw new Error('Value invalid')
    })

    this.red = red
    this.green = green
    this.blue = blue
  }

  public static fromArray(value: number[] | null): Color {
    if (!value || value.length !== 3) {
      throw new Error('Value invalid')
    }

    return new Color(value[0], value[1], value[2])
  }

  public toArray(): ColorTriple {
    return [this.red, this.green, this.blue]
  }

  public copy(): Color {
    return new Color(...this.toArray())
  }
}

export interface DesignConfigInterface {
  primaryColor?: Color
  secondaryColor?: Color
  logo?: DesignConfigImage
  loginBackground?: DesignConfigImage
  portalBackground?: DesignConfigImage
  loginLogoBgColor?: Color
  loginLogo?: DesignConfigImage
}

export interface DesignConfigFormImage {
  desktop: UploadedFileId | null
  mobile: UploadedFileId | null
}

export interface DesignConfigForm {
  primaryColor?: Color
  secondaryColor?: Color
  logo: DesignConfigFormImage
  loginBackground: DesignConfigFormImage
  portalBackground: DesignConfigFormImage
  loginLogoBgColor?: Color
  loginLogo: DesignConfigFormImage
}

export class DesignConfig implements DesignConfigInterface {
  constructor(
    public primaryColor?: Color,
    public secondaryColor?: Color,
    public logo?: DesignConfigImage,
    public loginBackground?: DesignConfigImage,
    public portalBackground?: DesignConfigImage,
    public loginLogoBgColor?: Color,
    public loginLogo?: DesignConfigImage
  ) {}

  public static fromForm(form: DesignConfigForm): DesignConfig {
    return new DesignConfig(
      form.primaryColor,
      form.secondaryColor,
      DesignConfig.imageFromForm(form.logo),
      DesignConfig.imageFromForm(form.loginBackground),
      DesignConfig.imageFromForm(form.portalBackground),
      form.loginLogoBgColor,
      DesignConfig.imageFromForm(form.loginLogo)
    )
  }

  public static fromInterface(i: DesignConfigInterface): DesignConfig {
    const config = new DesignConfig()
    Object.assign(config, i)
    return config
  }

  private static imageFromForm(
    formImage: DesignConfigFormImage
  ): DesignConfigImage | undefined {
    if (formImage.mobile !== null && formImage.desktop !== null) {
      return new DesignConfigImage(formImage.mobile, formImage.desktop)
    }

    return undefined
  }

  public toInterface(): DesignConfigInterface {
    return {
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      logo: this.logo,
      loginBackground: this.loginBackground,
      portalBackground: this.portalBackground,
      loginLogoBgColor: this.loginLogoBgColor,
      loginLogo: this.loginLogo,
    }
  }
}
