













import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PDeleteConfirmModal',
  setup() {
    const visible = ref(false)
    return { visible }
  },
})
