import { inject, injectable } from 'inversify'
import Permission from '~/src/services/permissions/types/Permission'
import type { PermissionFactory } from '~/src/services/permissions/types/Permission'
import DifferentiatingPermission from '~/src/services/permissions/types/DifferentiatingPermission'
import type { DifferentiatingPermissionFactory } from '~/src/services/permissions/types/DifferentiatingPermission'
import SYMBOLS from '~/src/dependency_injection/Symbols'

@injectable()
export class DataPoolPermissionsService {
  public static DEFAULT_SERVICE_ID = 'VMq5eS'
  public static DEFAULT_GROUP = 1
  public static DEFAULT_GROUP_PERMISSIONS = {
    CREATE: 1,
    EDIT_OWN: 2,
    EDIT_ALL: 4,
  }

  public readonly create: Permission
  public readonly edit: DifferentiatingPermission

  public constructor(
    @inject(SYMBOLS.PermissionFactory)
    permissionFactory: PermissionFactory,
    @inject(SYMBOLS.DifferentiatingPermissionFactory)
    dPermissionFactory: DifferentiatingPermissionFactory
  ) {
    this.create = permissionFactory(
      DataPoolPermissionsService.DEFAULT_GROUP_PERMISSIONS.CREATE,
      DataPoolPermissionsService.DEFAULT_SERVICE_ID,
      DataPoolPermissionsService.DEFAULT_GROUP
    )
    this.edit = dPermissionFactory(
      DataPoolPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_OWN,
      DataPoolPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_ALL,
      DataPoolPermissionsService.DEFAULT_SERVICE_ID,
      DataPoolPermissionsService.DEFAULT_GROUP
    )
  }
}
