
import { ref, watch } from '@nuxtjs/composition-api';
import { fileIdToDataUrl } from '~/helpers/fileIdToDataUrl';
const __sfc_main = {};
__sfc_main.props = {
  fileId: {
    key: "fileId",
    required: true,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const dataUrl = ref<string>('');
  const props = __props;
  watch(() => props.fileId, async newFileId => {
    dataUrl.value = await fileIdToDataUrl(newFileId);
  }, {
    immediate: true
  });
  return {
    dataUrl
  };
};
export default __sfc_main;
