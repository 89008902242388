import { AxiosRequestConfig } from 'axios'
import merge from 'deepmerge'
import { IllegalArgumentException } from '~/types/portal'

export default class PageDecorator {
  decorate(page = 1, config?: AxiosRequestConfig): AxiosRequestConfig {
    if (page < 1) {
      throw new IllegalArgumentException(
        `Page (${page}) must be a positive integer.`
      )
    }

    if (!Number.isInteger(page)) {
      throw new IllegalArgumentException(
        `Page (${page}) must be a positive integer.`
      )
    }

    const pageConfig: AxiosRequestConfig = { params: { page } }

    return merge(config ?? {}, pageConfig)
  }
}
