import { inject, injectable } from 'inversify'
import DifferentiatingAllowable from './DifferentiatingAllowable'
import Permission from './Permission'
import { PermissionService } from '~/src/services/permissions/PermissionService'

@injectable()
export default class DifferentiatingPermission
  extends Permission
  implements DifferentiatingAllowable
{
  public maskForAll: number

  constructor(
    @inject(PermissionService) protected permissionService: PermissionService
  ) {
    super(permissionService)
    this.maskForAll = 0
  }

  isAllowed(): boolean {
    return this.permissionService.hasPermission(
      this.mask | this.maskForAll,
      this.groupId,
      this.serviceId
    )
  }

  isAllowedForOwn(): boolean {
    return this.permissionService.hasPermission(
      this.mask,
      this.groupId,
      this.serviceId
    )
  }

  isAllowedForAll(): boolean {
    return this.permissionService.hasPermission(
      this.maskForAll,
      this.groupId,
      this.serviceId
    )
  }

  isAllowedFor(id: string): boolean {
    return this.permissionService.isAllowed(
      id,
      this.mask,
      0,
      this.maskForAll,
      this.serviceId,
      this.groupId
    )
  }
}

export type DifferentiatingPermissionFactory = (
  mask: number,
  maskForAll: number,
  serviceId: string,
  groupId: number
) => DifferentiatingPermission
