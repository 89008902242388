import { RawLocation } from 'vue-router'
import { usePermissionFactory, useVxm } from '~/src/composables/dependency'
import { Page, PermissionObject } from '~/types/portal-config'
import { Vxm } from '~/types/portal'
import { PortalConfigStore } from '~/store/portalConfig'

export function usePathMapping(
  {
    portalConfig,
  }: Pick<Vxm, 'portalConfig'> | { portalConfig: PortalConfigStore } = useVxm()
) {
  const permissionFactory = usePermissionFactory()

  function extractPathFromRawLocation(
    rawLocation: RawLocation
  ): string | undefined {
    return typeof rawLocation === 'string' ? rawLocation : rawLocation.path
  }

  /**
   * /admin/(.*)
   * @param fullPath /admin/extensions
   */
  function getPaths(fullPath: string): string[] | undefined {
    if (!portalConfig.fetched) {
      return undefined
    }

    let foundMapping: [string, string | RegExp][] | undefined

    if (fullPath.startsWith('http')) {
      foundMapping = Object.entries(portalConfig.mapping.plain).filter(
        ([_path, _fullPath]) => _fullPath === fullPath
      )
    } else {
      foundMapping = Object.entries(portalConfig.mapping.regexp).filter(
        ([_path, regexp]) => regexp.test(fullPath)
      )
    }

    return foundMapping?.map(([path, _]) => path)
  }

  /**
   *
   * @param fullPath ex. /admin/extensions
   * @returns Page | undefined
   */
  function getPages(fullPath: string): Page[] {
    const paths = getPaths(fullPath) ?? []

    return paths
      .flatMap((path) => portalConfig.allPages[path])
      .filter((p) => p !== undefined)
  }

  /**
   *
   * @param fullPath /admin/extensions
   * @returns PermissionObject | undefined
   */
  function getPermissions(fullPath: string): PermissionObject[] {
    const path = getPaths(fullPath)

    if (!path) {
      return []
    }

    return path
      .flatMap((path) => portalConfig.permissions[path])
      .filter((p) => p !== undefined)
  }

  function checkPermission(fullPath: string): boolean {
    const permission = getPermissions(fullPath)

    return permission.every(({ bit, service, group }) => {
      return permissionFactory(bit, service, group).isAllowed()
    })
  }

  return {
    extractPathFromRawLocation,
    getPaths,
    getPages,
    getPermissions,
    checkPermission,
  }
}
