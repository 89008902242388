import type { LocaleObject } from 'nuxt-i18n/types/index'
import { BaseUser } from '../services/UserApiService'
import { PortalAccountAttributesConfig } from '~/types/portal'

export function getBaseAccountAttributes(
  locales: LocaleObject[]
): PortalAccountAttributesConfig<keyof Omit<BaseUser, 'additionalProperties'>> {
  return [
    {
      type: 'select',
      id: 'preferredLanguage',
      label: 'user.profile.preferred_language',
      options: [
        {
          text: 'common.browser_language',
          value: null,
        },
        ...locales
          .filter((locale) => locale.enabled)
          .map((locale) => ({
            text: `langs.${locale.code}`,
            value: locale.code,
          })),
      ],
    },
    {
      type: 'text',
      id: 'title',
      label: 'user.profile.academicTitle',
    },
    {
      type: 'text',
      id: 'firstName',
      label: 'user.profile.firstName',
    },
    {
      type: 'text',
      id: 'lastName',
      label: 'user.profile.lastName',
    },
    {
      type: 'date',
      id: 'birthDate',
      label: 'user.profile.birthDate',
      trailingIcon: 'calendar',
    },
    {
      type: 'text',
      id: 'phone',
      label: 'user.profile.phone',
    },
    {
      type: 'text',
      id: 'mobilePhone',
      label: 'user.profile.mobilePhone',
    },
    {
      type: 'job-title',
      id: 'jobTitle',
      label: 'user.profile.jobTitle',
    },
    {
      type: 'text',
      id: 'department',
      label: 'user.profile.department',
    },
  ]
}

export const baseAccountAttributes: PortalAccountAttributesConfig<
  keyof Omit<BaseUser, 'additionalProperties'>
> = [
  {
    type: 'select',
    id: 'preferredLanguage',
    label: 'user.profile.preferred_language',
    options: [
      {
        text: 'common.browser_language',
        value: null,
      },
      {
        text: 'langs.deu',
        value: 'deu',
      },
      {
        text: 'langs.eng',
        value: 'eng',
      },
    ],
  },
  {
    type: 'text',
    id: 'title',
    label: 'user.profile.academicTitle',
  },
  {
    type: 'text',
    id: 'firstName',
    label: 'user.profile.firstName',
  },
  {
    type: 'text',
    id: 'lastName',
    label: 'user.profile.lastName',
  },
  {
    type: 'date',
    id: 'birthDate',
    label: 'user.profile.birthDate',
    trailingIcon: 'calendar',
  },
  {
    type: 'text',
    id: 'phone',
    label: 'user.profile.phone',
  },
  {
    type: 'text',
    id: 'mobilePhone',
    label: 'user.profile.mobilePhone',
  },
  {
    type: 'job-title',
    id: 'jobTitle',
    label: 'user.profile.jobTitle',
  },
  {
    type: 'text',
    id: 'department',
    label: 'user.profile.department',
  },
]
