import { Context, Plugin } from '@nuxt/types'
import type { LocaleObject, NuxtI18nInstance } from 'nuxt-i18n'
import { isArray } from 'lodash-es'
import { makeAssetUrl } from '~/src/composables/useAsset'
import { useVxm } from '~/src/composables/dependency'
import { assertLocalObjectArray } from '~/src/functions/locales'

async function handleLocale(
  locale: LocaleObject,
  viewportName: string,
  makeUrl: (path: string) => string,
  i18n: NuxtI18nInstance
) {
  try {
    const response = await fetch(makeUrl(`/${locale.iso}.json`))
    const messages = await response.json()

    i18n.mergeLocaleMessage(locale.code, {
      portal: { name: viewportName },
      ...messages,
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const plugin: Plugin = async ({ $config, i18n }: Context) => {
  const vxm = useVxm()
  let configLocaleKeys: string[]

  if (isArray(vxm.portalConfig.config?.locales)) {
    configLocaleKeys = vxm.portalConfig.config?.locales ?? []
  } else {
    configLocaleKeys =
      vxm.portalConfig.config?.locales?.locales.map((locale) => locale.key) ??
      []
  }

  assertLocalObjectArray(i18n.locales)

  const localesByCode = new Map<string, LocaleObject>()
  for (const locale of i18n.locales) {
    localesByCode.set(locale.code, locale)
  }

  for (const locale of configLocaleKeys) {
    const existingLocale = localesByCode.get(locale)
    if (existingLocale !== undefined) {
      existingLocale.enabled = true
    }
  }

  const makeUrl = (path: string) => makeAssetUrl(path, $config.assetsURL)

  await Promise.all(
    i18n.locales.map((locale) => {
      return locale.enabled
        ? handleLocale(locale, vxm.portalConfig.title, makeUrl, i18n)
        : Promise.resolve()
    })
  )
}

export default plugin
