






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'IframeLayout',
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
