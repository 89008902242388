import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { ref } from '@nuxtjs/composition-api'
import { debounce } from 'lodash-es'

Vue.use(VueCompositionApi)

let viewWidth = window.innerWidth

const minSm = 640
const minMd = 768
const minLg = 1024
const minXl = 1280
const minXxl = 1536

const isMaxXs = ref(viewWidth < minSm)
const isMinSm = ref(viewWidth >= minSm)
const isMaxSm = ref(viewWidth < minMd)
const isMinMd = ref(viewWidth >= minMd)
const isMaxMd = ref(viewWidth < minLg)
const isMinLg = ref(viewWidth >= minLg)
const isMaxLg = ref(viewWidth < minXl)
const isMinXl = ref(viewWidth >= minXl)
const isMinXxl = ref(viewWidth >= minXxl)

function updateMinBreakpoints() {
  viewWidth = window.innerWidth

  isMaxXs.value = viewWidth < minSm
  isMinSm.value = viewWidth >= minSm
  isMaxSm.value = viewWidth < minMd
  isMinMd.value = viewWidth >= minMd
  isMaxMd.value = viewWidth < minLg
  isMinLg.value = viewWidth >= minLg
  isMaxLg.value = viewWidth < minXl
  isMinXl.value = viewWidth >= minXl
  isMinXxl.value = viewWidth >= minXxl
}

window.addEventListener('resize', debounce(updateMinBreakpoints, 90))

export {
  minSm,
  minMd,
  minLg,
  minXl,
  minXxl,
  isMaxXs,
  isMinSm,
  isMaxSm,
  isMinMd,
  isMaxMd,
  isMinLg,
  isMaxLg,
  isMinXl,
  isMinXxl,
}
