// @ts-nocheck
export function optIn(siteId: string) {
  // Leadinfo tracking code
  ;(function (l, e, a, d, i, n, f, o) {
    if (!l[i]) {
      l.GlobalLeadinfoNamespace = l.GlobalLeadinfoNamespace || []
      l.GlobalLeadinfoNamespace.push(i)
      l[i] = function () {
        /* eslint-disable-next-line */
        ;(l[i].q = l[i].q || []).push(arguments)
      }
      l[i].t = l[i].t || n
      l[i].q = l[i].q || []
      o = e.createElement(a)
      o.async = 1
      o.src = d
      e.head.append(o)
    }
  })(
    window,
    document,
    'script',
    'https://cdn.leadinfo.eu/ping.js',
    'leadinfo',
    siteId
  )
}

export function optOut() {
  // Workaround for Leadinfo because it doesn't have an opt-out method
  window.location.reload()
}
