
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { watch } from '@nuxtjs/composition-api'
import { Color } from '~/types/DesignConfig'

export default defineComponent({
  props: {
    primaryColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
    secondaryColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
    loginLogoBgColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
    scope: {
      type: String as PropType<'local' | 'global'>,
      default: 'global',
    },
  },
  setup(props) {
    const properties = computed(() => {
      const properties: Map<string, string> = new Map()

      if (props.primaryColor) {
        properties.set(
          '--p-color-primary-100',
          props.primaryColor.toArray().join(',')
        )
        properties.set(
          '--color-primary-100-rgb',
          props.primaryColor.toArray().join(' ')
        )
      }

      if (props.secondaryColor) {
        properties.set(
          '--p-color-secondary-100',
          props.secondaryColor.toArray().join(',')
        )

        properties.set(
          '--color-secondary-100-rgb',
          props.secondaryColor.toArray().join(' ')
        )
      }

      if (props.loginLogoBgColor) {
        properties.set(
          '--p-bg-color-logo-layout',
          `rgb(${props.loginLogoBgColor.toArray().join(',')})`
        )
      }

      return properties
    })

    watch(
      () => [props.scope, properties.value] as const,
      ([newScope, newProperties]) => {
        if (newScope === 'global') {
          for (const [key, value] of newProperties) {
            document.documentElement.style.setProperty(key, value)
          }
        }
      },
      { immediate: true }
    )

    const themeStyles = computed(() => {
      if (props.scope === 'global') {
        return {}
      }

      return Object.fromEntries(properties.value.entries())
    })

    return { themeStyles }
  },
})
