
import type { FlyoutItem } from '~/components/PFlyout.vue';
import PFlyout from '~/components/PFlyout.vue';
const __sfc_main = {};
__sfc_main.props = {
  submenu: {
    key: "submenu",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function noop() {}
  return {
    noop
  };
};
__sfc_main.components = Object.assign({
  PFlyout
}, __sfc_main.components);
export default __sfc_main;
