import { AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import SYMBOLS from '../dependency_injection/Symbols'
import { UniqueIdService } from './UniqueIdService'

export class FileNotFoundError extends Error {}

export type UploadedFileId = string

@injectable()
export default class FileApiService {
  public constructor(
    @inject(SYMBOLS.Axios) private axios: AxiosInstance,
    private uniqueIdService: UniqueIdService
  ) {}

  public async download(fileId: UploadedFileId): Promise<Blob> {
    const response = await fetch(
      `${this.axios.defaults.baseURL}/file/${fileId}`,
      {
        method: 'GET',
      }
    )

    if (response.status === 404) {
      throw new FileNotFoundError()
    }

    const fileUrl: string = await response.json()

    if (typeof fileUrl !== 'string') {
      throw new TypeError('expected string')
    }

    const data = await fetch(fileUrl, { method: 'GET' })

    return data.blob()
  }

  public async upload(file: File): Promise<UploadedFileId> {
    const fileId = this.uniqueIdService.getV4Uuid()
    const { action, inputs } = (
      await this.axios.post(`file/${fileId}`, { mimeType: file.type })
    ).data

    const formData = new FormData()
    formData.set('key', inputs.key)
    formData.set('acl', inputs.acl)
    formData.set('Content-Type', file.type)
    formData.set(
      'X-Amz-Server-Side-Encryption',
      inputs['X-Amz-Server-Side-Encryption']
    )
    formData.set('X-Amz-Credential', inputs['X-Amz-Credential'])
    formData.set('X-Amz-Algorithm', inputs['X-Amz-Algorithm'])
    formData.set('X-Amz-Date', inputs['X-Amz-Date'])

    if (inputs['X-Amz-Security-Token']) {
      formData.set('X-Amz-Security-Token', inputs['X-Amz-Security-Token'])
    }

    formData.set('Policy', inputs.Policy)
    formData.set('X-Amz-Signature', inputs['X-Amz-Signature'])
    formData.set('file', file)

    await fetch(action, {
      method: 'POST',
      body: formData,
    })

    return fileId
  }
}
