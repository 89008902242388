import { RawLocation } from 'vue-router'

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>

export interface NavigationItem {
  route: RawLocation
  icon: string
  activeIcon: string
  label: string
  exact: boolean
  userHasPermission: boolean
  isExternal: boolean
  openInNewTab: boolean
  type?: 'root' | 'admin'
}

export type NavigationItemConstructor = Optional<
  NavigationItem,
  'activeIcon' | 'exact' | 'isExternal' | 'userHasPermission' | 'openInNewTab'
>

export interface UseNavigation {
  makeItem: (navigationItem: NavigationItemConstructor) => NavigationItem
}

export default function useNavigation(): UseNavigation {
  function makeItem({
    route,
    icon,
    activeIcon = undefined,
    label,
    exact = false,
    isExternal = false,
    userHasPermission = undefined,
    openInNewTab = false,
    type,
  }: NavigationItemConstructor) {
    return {
      route,
      icon,
      activeIcon: activeIcon ?? icon,
      label,
      exact,
      userHasPermission: userHasPermission ?? true,
      isExternal,
      openInNewTab,
      type,
    }
  }

  return { makeItem }
}
