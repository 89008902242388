import { Plugin } from '@nuxt/types'
import { isEmpty } from 'lodash-es'
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import { email, min, required } from 'vee-validate/dist/rules'
import type { ValidationRule } from 'vee-validate/dist/types/types'
import Vue from 'vue'
import {
  useCompanyApiService,
  useTranslator,
} from '~/src/composables/dependency'
import CompanyApiService from '~/src/services/CompanyApiService'

const translateMessage = (
  name: string,
  _field: string,
  params: Record<string, unknown>
) => {
  const translator = useTranslator()
  return translator.t(name, params) as string
}

const extendWithTranslatedMessage = (
  name: string,
  schema: ValidationRule,
  translationKeyName?: string
) => {
  const nameToUse = translationKeyName ?? name
  const snakeCaseName = nameToUse.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`
  )
  const translationKey = `validation.messages.${snakeCaseName}`
  extend(name, {
    ...schema,
    message: (field, params) => translateMessage(translationKey, field, params),
  })
}

const searchCompany = async (
  query: string,
  excludedId: string | undefined,
  apiService: CompanyApiService,
  page = 1
): Promise<boolean> => {
  const { pages, result } = await apiService.search({
    search: query,
    hasParent: undefined,
    page,
  })

  const matching = result.find(
    (company) =>
      company.name.localeCompare(query, undefined, {
        usage: 'search',
        sensitivity: 'base',
      }) === 0 && company.uuid !== excludedId
  )

  const noMatch = matching === undefined

  if (noMatch && page < pages) {
    return searchCompany(query, excludedId, apiService, page + 1)
  }

  return noMatch
}

const veeValidatePlugin: Plugin = () => {
  extendWithTranslatedMessage('email', email)
  extendWithTranslatedMessage('min', min)
  extendWithTranslatedMessage('required', required)

  extendWithTranslatedMessage(
    'required-multiselect',
    {
      validate: (value) => {
        if (value === null) {
          return false
        }

        if (isEmpty(value)) {
          return false
        }

        return true
      },
      computesRequired: true,
    },
    'required'
  )

  extendWithTranslatedMessage('password', {
    params: [
      {
        name: 'groups',
        default: 3,
        cast: (value) => parseInt(value),
      },
    ],
    validate: (value, params) => {
      if (typeof value !== 'string') {
        return false
      }

      params = params as { groups: number }

      const characterGroupMatches = [
        value.match(/[a-z]/),
        value.match(/[A-Z]/),
        value.match(/\d/),
        value.match(/[`~!@#$%^&*()_=+[{\]}\\\\|;:'",<.>/?-]/),
      ].filter((match) => match !== null)

      return characterGroupMatches.length >= params.groups
    },
  })

  extendWithTranslatedMessage('company-name', {
    params: [
      {
        name: 'allowed',
      },
    ],
    validate: async (value, params) => {
      const companyApiService = useCompanyApiService()

      if (Array.isArray(params)) {
        return false
      }

      return await searchCompany(value, params.allowed, companyApiService)
    },
  })

  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)

  setInteractionMode('passive')
}

export default veeValidatePlugin
