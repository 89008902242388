import Address from '~/src/models/Address'
import CompanyLocation from '~/src/models/CompanyLocation'
import { UserId } from '~/src/services/UserApiService'

export interface CompanyConstructor {
  uuid: string
  name: string
  address: Address
  locations: CompanyLocation[]
  contacts: UserId[]
  reference: string | null
  vatNumber: string | null
  parent: string | null
}

export default class Company {
  public uuid: string
  public name: string
  public address: Address
  public locations: CompanyLocation[]
  public contacts: UserId[]
  public reference: string | null
  public vatNumber: string | null
  public parent: string | null

  constructor({
    uuid = '',
    name = '',
    address,
    locations = [],
    contacts = [],
    reference = null,
    vatNumber = null,
    parent = null,
  }: Partial<CompanyConstructor>) {
    this.uuid = uuid
    this.name = name
    this.address = new Address(address ? { ...address } : {})
    this.locations = locations.map((l) => new CompanyLocation(l ?? {}))
    this.contacts = contacts
    this.reference = reference
    this.vatNumber = vatNumber
    this.parent = parent
  }
}
