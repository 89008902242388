import { diff } from 'deep-object-diff'
import { pick } from 'lodash-es'
import Diffable from '~/src/services/types/Diffable'

export type ShallowDiffResult<T> = Partial<T> | T | undefined

// eslint-disable-next-line @typescript-eslint/ban-types
export default class ShallowDiffer<T extends object>
  implements Diffable<T, ShallowDiffResult<T>>
{
  diff(newer: T): ShallowDiffResult<T>
  diff(newer: T, old: T): ShallowDiffResult<T>
  diff(newer: T, old?: T): ShallowDiffResult<T> {
    let result: ShallowDiffResult<T>

    if (Object.keys(newer).length === 0) {
      return undefined
    }

    if (old === undefined) {
      result = newer
    } else {
      result = pick(
        newer,
        Object.keys(diff(old, newer))
      ) as ShallowDiffResult<T>
    }

    if (result === undefined) {
      return undefined
    }

    if (Object.keys(result).length === 0) {
      return undefined
    }

    return result
  }
}
