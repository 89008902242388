

















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PHeadline',
  props: {
    tag: {
      type: String,
      default: 'h1',
      validator: (tag: string) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tag),
    },
    size: {
      type: String,
      default: null,
      validator: (size: string) =>
        ['lg', 'xl', '2xl', '3xl', '4xl', '5xl'].includes(size),
    },
    marginBottom: {
      type: String,
      default: null,
      validator: (marginBottom: string) =>
        ['xs', 'sm', 'md', 'lg', 'xl'].includes(marginBottom),
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    onPrimary: {
      type: Boolean,
      default: false,
    },
    fontFamilyBasic: {
      type: Boolean,
      default: false,
    },
    textColorDefault: {
      type: Boolean,
      default: false,
    },
    breakWord: {
      type: Boolean,
      default: false,
    },
  },
})
