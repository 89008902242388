/* eslint-disable require-await */
import { createModule, mutation, action } from 'vuex-class-component'
const VuexModule = createModule({
  namespaced: 'navigation',
  strict: false,
  target: 'nuxt',
})

export class NavigationStore extends VuexModule {
  isMainOpen = false
  isMainExtended = false

  @mutation
  SET_IS_MAIN_OPEN(isOpen: boolean): void {
    this.isMainOpen = isOpen
  }

  @action
  async setIsMainOpen(isOpen: boolean): Promise<void> {
    this.SET_IS_MAIN_OPEN(isOpen)
  }

  @action
  async openMain(): Promise<void> {
    this.SET_IS_MAIN_OPEN(true)
  }

  @action
  async closeMain(): Promise<void> {
    this.SET_IS_MAIN_OPEN(false)
  }

  @mutation
  SET_MAIN_IS_EXTENDED(isExtended: boolean): void {
    this.isMainExtended = isExtended
  }

  @action
  async setIsMainExtended(isExtended: boolean): Promise<void> {
    this.SET_MAIN_IS_EXTENDED(isExtended)
  }
}
