import { createModule, action, mutation } from 'vuex-class-component'
import Company from '~/src/models/Company'
import { useCompanyApiService } from '~/src/composables/dependency'

const VuexModule = createModule({
  namespaced: 'companies',
  strict: false,
  target: 'nuxt',
})

export class CompanyStore extends VuexModule {
  companies: Record<string, Company> = {}

  @mutation
  setCompanies(companies: Record<string, Company>): void {
    this.companies = companies
  }

  @action
  addCompany(company: Company): Promise<void> {
    this.setCompanies({
      ...this.companies,
      [company.uuid]: company,
    })

    return Promise.resolve()
  }

  @action
  addCompanies(companies: Company[]): Promise<void> {
    const companiesById = Object.fromEntries(companies.map((c) => [c.uuid, c]))

    this.setCompanies({
      ...this.companies,
      ...companiesById,
    })

    return Promise.resolve()
  }

  @action
  async fetchCompanyById({
    id,
    ignoreExisting = false,
  }: {
    id: string
    ignoreExisting?: boolean
  }): Promise<void> {
    if (!ignoreExisting && this.companies[id] !== undefined) {
      return
    }

    const companyApiService = useCompanyApiService()
    const company = await companyApiService.getById(id)
    return this.addCompany(company)
  }
}
