import { useContext } from '@nuxtjs/composition-api'
import { appendVersionQuery, makeAssetUrl } from '~/helpers/makeAssetUrl'

export { appendVersionQuery, makeAssetUrl }

export function useAsset(): (path: string) => string {
  const {
    $config: { assetsURL },
  } = useContext()
  return (path: string) => makeAssetUrl(path, assetsURL)
}
