var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.href && !_vm.disabled ? 'a' : !_vm.to || _vm.disabled ? 'button' : 'nuxt-link',_vm._g(_vm._b({tag:"component",class:[
    'p-btn',
    {
      'p-btn--primary': _vm.primary,
      'p-btn--secondary': _vm.secondary,
      'p-btn--tertiary': _vm.tertiary,
      'p-btn--circle-sm': _vm.circleSm,
      'p-btn--circle-md': _vm.circleMd,
      'p-btn--circle-lg': _vm.circleLg,
      'p-btn--disabled': _vm.disabled,
      'p-btn--card-footer-left': _vm.cardFooterLeft,
      'p-btn--card-footer-right': _vm.cardFooterRight,
    } ],attrs:{"to":_vm.to,"href":_vm.href,"form":_vm.form,"disabled":_vm.disabled,"type":_vm.to === null && _vm.href === null ? _vm.type : null}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.$scopedSlots.icon)?_c('span',{staticClass:"p-btn__icon"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.text)?_c('span',{staticClass:"p-btn__text"},[_vm._t("text")],2):_c('span',{staticClass:"p-btn__text"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }