import { Context } from '@nuxt/types'
import { usePathMapping } from '~/src/composables/usePathMapping'

export default function ({ redirect, route }: Context): void {
  const { checkPermission } = usePathMapping()

  const hasPermission = checkPermission(route.path)

  if (hasPermission) {
    return
  }

  redirect('/')
}
