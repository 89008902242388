









import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useVxm } from '~/src/composables/dependency'

export default defineComponent({
  name: 'DefaultLayout',
  setup(_props) {
    const vxm = useVxm()
    const configuration = computed(() => vxm.configuration.configuration)
    const background = computed(() => configuration.value?.portalBackground)

    return {
      background,
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.localeProperties['639-1'],
      },
    }
  },
})
