









import {
  defineComponent,
  ref,
  watchEffect,
  Ref,
  computed,
} from '@nuxtjs/composition-api'
import InlineSvg from 'vue-inline-svg'
import { useAsset } from '~/src/composables/useAsset'
import fetchSvg from '~/utils/fetch-svg'

export default defineComponent({
  name: 'PIcon',
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      required: true,
    },
    height: {
      type: [String, Number],
      required: false,
      default: null,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '20',
    },
  },
  setup(props) {
    const src: Ref<string | null> = ref(null)
    const asset = useAsset()
    const iconUrl = computed(() => asset(`/icons/${props.name}.svg`))

    watchEffect(async () => {
      src.value = await fetchSvg(iconUrl.value)
    })

    return { src }
  },
})
