import { useQuery } from 'vue-query'
import { computed, unref, Ref } from '@nuxtjs/composition-api'
import { useUserApiService } from '~/src/composables/dependency'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUserInfoQuery(
  userId: Ref<string | undefined> | string | undefined,
  {
    refetchOnWindowFocus = true,
  }: {
    refetchOnWindowFocus?: boolean
  } = {}
) {
  const userApiService = useUserApiService()
  const enabled = computed(() => unref(userId) !== undefined)

  return useQuery(
    ['user', 'info', userId],
    () => {
      const userIdValue = unref(userId)

      if (userIdValue === undefined) {
        throw new Error('userId may not be undefined')
      }

      return userApiService.info(userIdValue)
    },
    { staleTime: 5000, enabled, refetchOnWindowFocus }
  )
}
