export interface Token {
  email: string
  sub: string
  perms: string
  cid: string
  lng: string | null
}

export function transformTokenData(token: Token) {
  return {
    email: token.email,
    id: token.sub,
    permissions: token.perms,
    company: token.cid,
    language: token.lng ?? null,
  }
}
