import { inject, injectable } from 'inversify'
import { AxiosInstance } from 'axios'
import SYMBOLS from '~/src/dependency_injection/Symbols'

export type GroupId = string
@injectable()
export default class GroupApiService {
  public constructor(@inject(SYMBOLS.Axios) private axios: AxiosInstance) {}

  public async get(options?: {
    page?: number
    query?: string
  }): Promise<Api.ListResponse<Groups.Group>> {
    const params: Record<string, unknown> = {
      page: options?.page ?? 1,
    }

    if (options?.query !== undefined) {
      params.query = options.query
    }

    const response = await this.axios.get<Api.ListResponse<Groups.Group>>(
      '/groups',
      { params }
    )

    return response.data
  }

  public async getById(id: string): Promise<Groups.Group> {
    const response = await this.axios.get<Groups.Group>(`/groups/${id}`)

    return response.data
  }
}
