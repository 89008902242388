




































































import {
  defineComponent,
  PropType,
  ref,
  useRoute,
  watchEffect,
} from '@nuxtjs/composition-api'
import { RawLocation } from 'vue-router'
import PIcon from '~/components/PIcon.vue'

export type FlyoutItem = {
  icon: string
  activeIcon?: string
  label: string
  to?: RawLocation
  href?: string
  target?: string
  action?: () => unknown
  submenu?: FlyoutItem[]
}

export default defineComponent({
  name: 'PFlyout',
  components: { PIcon },
  props: {
    headerText: {
      type: String,
      required: false,
      default: null,
    },
    position: {
      type: String as PropType<'default' | 'left'>,
      required: false,
      default: 'default',
    },
    items: {
      type: Array as PropType<FlyoutItem[]>,
      default: () => [],
    },
  },
  setup() {
    const shown = ref(false)
    const route = useRoute()
    const routeName = ref(route.value.name)

    const toggleShow = (value: boolean | UIEvent) => {
      shown.value = typeof value === 'boolean' ? value : !shown.value
    }

    watchEffect(() => {
      const { name } = route.value

      if (routeName.value !== name) {
        shown.value = false
        routeName.value = name
      }
    })

    return { shown, toggleShow, route }
  },
})
