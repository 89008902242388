
import { ref } from '@nuxtjs/composition-api'
import { computed, defineComponent, Ref } from '@vue/composition-api'
import { useVxm } from '~/src/composables/dependency'

export default defineComponent({
  setup() {
    const scrolled: Ref<boolean> = ref(false)
    const vxm = useVxm()

    const breadcrumbItems = computed(() => vxm.breadcrumb.items)

    const handleTopVisibilityChange = (isVisible: boolean) => {
      scrolled.value = !isVisible
    }

    return {
      breadcrumbItems,
      handleTopVisibilityChange,
      scrolled,
    }
  },
})
