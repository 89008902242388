import type { PermissionObject } from '~/types/portal-config'
import { getGroupAndBit } from '~/helpers/config/getGroupAndBit'

export function createPermissionObject(
  service: string,
  permissionBit?: number
): PermissionObject | undefined {
  if (permissionBit === undefined) {
    return undefined
  }

  return {
    service,
    ...getGroupAndBit(permissionBit),
  }
}
