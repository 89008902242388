import { action, createModule, mutation } from 'vuex-class-component'
import { cloneDeep } from 'lodash-es'
import type {
  GroupPermissions,
  PermissionsDefinition,
} from '~/src/services/PermissionsApiService'
import {
  usePermissionsApiService,
  useTranslator,
} from '~/src/composables/dependency'

const VuexModule = createModule({
  namespaced: 'permissions',
  strict: false,
  target: 'nuxt',
})

export class PermissionStore extends VuexModule {
  permissions: PermissionsDefinition = []
  permissionsFetched = false

  @mutation
  SET_PERMISSIONS(permissions: PermissionsDefinition): void {
    this.permissions = permissions
  }

  @mutation
  SET_PERMISSIONS_FETCHED(): void {
    this.permissionsFetched = true
  }

  get flatPermissions(): GroupPermissions[] {
    return this.permissions.flatMap(
      (permissionGroup) => permissionGroup.permissions
    )
  }

  get flatPermissionIds(): string[] {
    // Adoro il permissino!
    return this.flatPermissions.map((permissino) => permissino.id)
  }

  get flatPermissionsById(): Record<string, GroupPermissions> {
    return this.permissions == null
      ? {}
      : Object.fromEntries(
          this.flatPermissions.map((permission) => [permission.id, permission])
        )
  }

  get translatedPermissions(): PermissionsDefinition {
    const translate = useTranslator()
    const translatedPermissions: PermissionsDefinition = []
    this.permissions.forEach((group) => {
      const newGroup = cloneDeep(group)
      newGroup.label = translate.t(newGroup.label) as string
      newGroup.permissions.map(
        (permission) =>
          (permission.name = translate.t(permission.name) as string)
      )
      newGroup.permissions.sort((a: GroupPermissions, b: GroupPermissions) =>
        a.name < b.name ? -1 : 1
      )
      translatedPermissions.push(newGroup)
    })

    translatedPermissions.sort((a, b) => (a.label < b.label ? -1 : 1))

    return translatedPermissions
  }

  @action
  async fetchPermissions(): Promise<void> {
    if (!this.permissionsFetched) {
      this.SET_PERMISSIONS(await usePermissionsApiService().list())
      this.SET_PERMISSIONS_FETCHED()
    }
  }
}
