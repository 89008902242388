import { render, staticRenderFns } from "./base.vue?vue&type=template&id=5c2b4a62&"
import script from "./base.vue?vue&type=script&lang=ts&"
export * from "./base.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLayout: require('/app/components/layouts/BaseLayout.vue').default})
