import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import { makeAssetUrl } from '~/src/composables/useAsset'

declare module 'vue/types/vue' {
  interface Vue {
    $assetUrl(path: string): string
  }
}

const plugin: Plugin = (context) => {
  Vue.prototype.$assetUrl = (path: string): string => {
    const assetUrl = context.$config.assetsURL

    return makeAssetUrl(path, assetUrl)
  }
}

export default plugin
