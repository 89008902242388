








import { defineComponent } from '@nuxtjs/composition-api'
import useTitle from '~/src/composables/useTitle'

export default defineComponent({
  name: 'LayoutDefault',
  setup() {
    useTitle()
  },
  head: {},
})
