





































import { computed, defineComponent } from '@nuxtjs/composition-api'
import { ComputedRef, Ref, ref, watch } from '@vue/composition-api'
import { useVxm } from '~/src/composables/dependency'
import { DesignConfigInterface } from '~/types/DesignConfig'

export default defineComponent({
  name: 'PLogo',
  props: {
    login: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vxm = useVxm()
    const config: ComputedRef<DesignConfigInterface | null> = computed(
      () => vxm.configuration.configuration
    )
    const logoMobile: Ref<string | null> = ref(null)
    const logoDesktop: Ref<string | null> = ref(null)

    watch(
      () => config.value,
      (newConfig) => {
        if (!props.login) {
          logoMobile.value = newConfig?.logo?.mobile ?? null
          logoDesktop.value = newConfig?.logo?.desktop ?? null
        } else if (props.login) {
          logoMobile.value = newConfig?.loginLogo?.mobile ?? null
          logoDesktop.value = newConfig?.loginLogo?.desktop ?? null
        }
      },
      { immediate: true }
    )

    return { logoMobile, logoDesktop }
  },
})
