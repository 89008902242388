import { action, createModule, mutation } from 'vuex-class-component'
import { LocaleObject } from 'nuxt-i18n/types/index'
import {
  PortalAccountAttribute,
  PortalAccountAttributesConfig,
} from '~/types/portal'
import { getBaseAccountAttributes } from '~/src/config/baseAccountAttributes'
import { BaseUser } from '~/src/services/UserApiService'
import { makeAssetUrl } from '~/src/composables/useAsset'
import { useTranslator } from '~/src/composables/dependency'

const VuexModule = createModule({
  namespaced: 'accountAttributes',
  strict: false,
  target: 'nuxt',
})

export type FieldConfig = {
  base: PortalAccountAttributesConfig<
    keyof Omit<BaseUser, 'additionalProperties'>
  >
  additional: PortalAccountAttributesConfig
}

function isStringArray<T>(a: string[] | T[]): a is string[] {
  return typeof a[0] === 'string'
}

export class AccountAttributesStore extends VuexModule {
  config: PortalAccountAttributesConfig | null = null
  fetched = false

  registrationFields: string[] = []
  areFieldsFetched = false

  @mutation
  SET_CONFIG(config: PortalAccountAttributesConfig): void {
    this.config = config
  }

  @mutation
  SET_FETCHED(): void {
    this.fetched = true
  }

  @mutation
  SET_REGISTRATION_FIELDS(fields: string[]): void {
    this.registrationFields = fields
  }

  @mutation
  SET_FIELDS_FETCHED(): void {
    this.areFieldsFetched = true
  }

  @action
  async fetchConfig({ assetUrl }: { assetUrl: string }): Promise<void> {
    if (this.fetched) {
      return
    }

    const response = await fetch(
      makeAssetUrl('/account-attributes.json', assetUrl)
    )
    const config = await response.json()

    this.SET_CONFIG(config)
    this.SET_FETCHED()
  }

  @action
  async fetchRegistrationFields({
    assetUrl,
  }: {
    assetUrl: string
  }): Promise<void> {
    if (this.areFieldsFetched) {
      return
    }

    const response = await fetch(
      makeAssetUrl('/registration-fields.json', assetUrl)
    )
    const fields = await response.json()

    this.SET_REGISTRATION_FIELDS(fields)
    this.SET_FIELDS_FETCHED()
  }

  get registrationFieldConfig(): FieldConfig {
    if (!this.areFieldsFetched || !(this.fetched && this.config !== null)) {
      return {
        base: [],
        additional: [],
      }
    }

    const includedInRegistrationFields = ({ id }: PortalAccountAttribute) =>
      this.registrationFields.includes(id)

    const { locales } = useTranslator()

    let enabledLocales: LocaleObject[] = []

    if (locales.length !== 0 && !isStringArray(locales)) {
      enabledLocales = locales
    }

    const base = getBaseAccountAttributes(enabledLocales)
      .filter(includedInRegistrationFields)
      .map((field) => ({
        ...field,
        required: field.id !== 'preferredLanguage',
      }))

    const config = {
      base,
      additional: this.config.filter(includedInRegistrationFields),
    }

    return config
  }

  get fieldConfig(): FieldConfig {
    if (!this.areFieldsFetched || !(this.fetched && this.config !== null)) {
      return {
        base: [],
        additional: [],
      }
    }

    const { locales } = useTranslator()

    return {
      base: getBaseAccountAttributes(locales as LocaleObject[]),
      additional: this.config,
    }
  }
}
