





































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'PButton',
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    form: {
      type: String,
      default: null,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    circleSm: {
      type: Boolean,
      default: false,
    },
    circleMd: {
      type: Boolean,
      default: false,
    },
    circleLg: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cardFooterLeft: {
      type: Boolean,
      default: false,
    },
    cardFooterRight: {
      type: Boolean,
      default: false,
    },
  },
})
