import { onGlobalSetup } from '@nuxtjs/composition-api'
import { provideToast } from 'vue-toastification/composition'
import { Plugin } from '@nuxt/types'

const provideToastPlugin: Plugin = () => {
  onGlobalSetup(() => {
    provideToast({ timeout: 3000 })
  })
}

export default provideToastPlugin
