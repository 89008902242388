import { Middleware } from '@nuxt/types'
import {
  BreadcrumbConfig,
  BreadcrumbConfigRecord,
  Routed,
  Titled,
} from '~/types/portal'
import createBreadcrumb from '~/helpers/breadcrumb/createBreadcrumb'
import { useVxm } from '~/src/composables/dependency'
import extendBreadcrumb from '~/helpers/breadcrumb/extendBreadcrumb'

const middleware: Middleware = ({ route }) => {
  const vxm = useVxm()
  const breadcrumbConfig: BreadcrumbConfig = { ...vxm.breadcrumb.config }
  const portalConfig = vxm.portalConfig.config

  let extendedConfig: BreadcrumbConfigRecord = breadcrumbConfig

  const additionalPages: (Titled & Routed)[] = portalConfig
    ? [...portalConfig.navigationPages, ...portalConfig.iframes]
    : []

  additionalPages.forEach((page) => {
    extendedConfig = extendBreadcrumb(
      page.route.path,
      page.title,
      extendedConfig
    )
  })

  const newCrumbs = createBreadcrumb(route.fullPath, extendedConfig)

  vxm.breadcrumb.resetItems()
  vxm.breadcrumb.setMatchedItems(newCrumbs)
}

export default middleware
