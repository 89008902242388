import { AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'

export type GroupPermissions = {
  id: string
  name: string
}

export type ServiceGroups = {
  label: string
  permissions: GroupPermissions[]
}

export type PermissionsDefinition = ServiceGroups[]

@injectable()
export default class PermissionsApiService {
  private readonly axios: AxiosInstance

  public constructor(@inject(SYMBOLS.Axios) axios: AxiosInstance) {
    this.axios = axios
  }

  public async list(): Promise<PermissionsDefinition> {
    const result = await this.axios.get<PermissionsDefinition>('/permissions')

    return result.data
  }
}
