import type { InternalNavigationItem } from '~/store/portalConfig'
import useNavigation, { NavigationItem } from '~/src/composables/useNavigation'
import { checkNavigationItemPermission } from '~/helpers/navigation/checkNavigationItemPermission'

/**
 * Generates navigation items based on the configuration
 * inside the portalConfigStore, typically fetched from
 * viewport-assets.
 *
 * @param navigationItems - Navigation items to generate
 * @param filterByPermission - Whether to filter items which the user does not have permission to access
 * @returns Navigation items generated from the portal config
 */
export function createNavigationItems(
  navigationItems: InternalNavigationItem[],
  filterByPermission = true
): NavigationItem[] {
  const { makeItem } = useNavigation()

  const items = navigationItems.map((navigationItem) =>
    makeItem({
      route: navigationItem.target,
      icon: navigationItem.icon,
      activeIcon: `solid/${navigationItem.icon}`,
      label: navigationItem.label,
      userHasPermission: checkNavigationItemPermission(navigationItem),
      isExternal: navigationItem.external === true,
      openInNewTab: navigationItem.openInNewTab === true,
      exact: navigationItem.exact,
      type: navigationItem.type,
    })
  )

  if (filterByPermission) {
    return items.filter((item) => item.userHasPermission)
  }

  return items
}
