
import { computed } from '@nuxtjs/composition-api';
import { Wormhole } from 'portal-vue';
import { useVxm } from '~/src/composables/dependency';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const vxm = useVxm();
  const closeNav = () => vxm.navigation.closeMain();
  const submitBarHasSource = computed(() => Wormhole.hasContentFor('submit-bar'));
  const configuration = computed(() => vxm.configuration.configuration);
  return {
    closeNav,
    submitBarHasSource,
    configuration
  };
};
export default __sfc_main;
