import { Plugin } from '@nuxt/types'
import { makeAssetUrl } from '~/src/composables/useAsset'

function createLink(href: string, rel = 'stylesheet', type = 'text/css') {
  const link = document.createElement('link')
  link.rel = rel
  link.type = type
  link.href = href

  document.head.appendChild(link)
}

const plugin: Plugin = (ctx) => {
  const assetUrl = ctx.$config.assetsURL

  createLink(makeAssetUrl('/favicon.ico', assetUrl), 'icon', 'image/x-icon')
  createLink(makeAssetUrl('/p-fonts.css', assetUrl))
  createLink(makeAssetUrl('/p-global-variables.css', assetUrl))
  createLink(makeAssetUrl('/custom-properties.css', assetUrl))
}

export default plugin
