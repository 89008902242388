import { AxiosError, AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import SYMBOLS from '../dependency_injection/Symbols'
import { NotAllowedException } from '~/src/services/AbstractApiService'

export class RoleNotFoundError extends Error {}

export class RoleId {
  private readonly value: string

  public static fromString(value: string): RoleId {
    return new RoleId(value)
  }

  public toString(): string {
    return this.value
  }

  private constructor(value: string) {
    this.value = value
  }
}

type RolePermission = string

export interface NoRestriction {
  to: 'nobody'
}

export interface RestrictToGroups {
  to: 'groups'
  restrictTo: string[]
}

export type RoleRestrictAccess = NoRestriction | RestrictToGroups

export class Role {
  public constructor(
    public id: RoleId | null,
    public title: string,
    public description: string | null,
    public permissions: RolePermission[],
    public restrictAccess: RoleRestrictAccess
  ) {}
}

export interface RoleDto {
  uuid: string
  title: string
  description: string | null
  permissions: string[]
  restrictAccess: RoleRestrictAccess
}

@injectable()
export default class RoleApiService {
  public constructor(@inject(SYMBOLS.Axios) private axios: AxiosInstance) {}

  public async list(): Promise<Role[]> {
    let response = null
    try {
      response = await this.axios.get<RoleDto[]>('/role')
    } catch (e: unknown) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new RoleNotFoundError()
      }

      throw e
    }

    const roles = <Role[]>[]
    response.data.forEach((data) => {
      roles.push(
        new Role(
          RoleId.fromString(data.uuid),
          data.title,
          data.description ?? null,
          data.permissions,
          data.restrictAccess
        )
      )
    })
    return roles
  }

  public async create(role: Role): Promise<RoleId> {
    if (role.id !== null) {
      throw new TypeError('Id can not be given in post')
    }

    const data: Omit<RoleDto, 'uuid'> = {
      title: role.title,
      description: role.description,
      permissions: role.permissions,
      restrictAccess: role.restrictAccess,
    }
    const response = await this.axios.post('role', data)

    return RoleId.fromString(response.data.id)
  }

  public async update(role: Role): Promise<void> {
    if (role.id === null) {
      throw new TypeError('No id given')
    }
    const id = role.id.toString()
    const data: Omit<RoleDto, 'uuid'> = {
      title: role.title,
      description: role.description,
      permissions: role.permissions,
      restrictAccess: role.restrictAccess,
    }
    try {
      await this.axios.put(`role/${id}`, data)
    } catch (e: unknown) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new RoleNotFoundError()
      }

      throw e
    }
  }

  public async delete(role: Role): Promise<void> {
    if (role.id === null) {
      throw new TypeError('No id given')
    }

    const id = role.id.toString()

    try {
      await this.axios.delete(`role/${id}`)
    } catch (e: unknown) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new RoleNotFoundError()
      } else if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }

      throw e
    }
  }
}
