





import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PSubmitBar',
  props: {
    inModal: {
      type: Boolean,
      default: false,
    },
  },
})
