/* eslint-disable require-await */
import { action, createModule, mutation } from 'vuex-class-component'
import { makeAssetUrl } from '~/src/composables/useAsset'
import type { BreadcrumbConfig, BreadcrumbItem } from '~/types/portal'

const VuexModule = createModule({
  namespaced: 'breadcrumb',
  strict: false,
  target: 'nuxt',
})

export class BreadcrumbStore extends VuexModule {
  config: BreadcrumbConfig = { '': { label: 'index.title' } }
  matchedItems: BreadcrumbItem[] = []
  addedItems: BreadcrumbItem[] = []
  fetched = false

  @mutation
  SET_CONFIG(config: BreadcrumbConfig): void {
    this.config = { ...config }
  }

  @mutation
  SET_FETCHED(): void {
    this.fetched = true
  }

  @action
  async fetchConfig({ assetUrl }: { assetUrl: string }): Promise<void> {
    if (this.fetched) {
      return
    }

    const response = await fetch(makeAssetUrl('/breadcrumbs.json', assetUrl))
    const config = await response.json()

    this.SET_CONFIG(config)
    this.SET_FETCHED()
  }

  @mutation
  SET_MATCHED_ITEMS(items: BreadcrumbItem[]): void {
    this.matchedItems = items
  }

  @action
  async setMatchedItems(items: BreadcrumbItem[]): Promise<void> {
    this.SET_MATCHED_ITEMS(items)
  }

  @mutation
  SET_ITEMS(items: BreadcrumbItem[]): void {
    this.addedItems = items
  }

  @action
  async setItems(items: BreadcrumbItem[]): Promise<void> {
    this.SET_ITEMS(items)
  }

  @mutation
  ADD_ITEMS(items: BreadcrumbItem[]): void {
    this.addedItems = this.addedItems.concat(items)
  }

  @action
  async addItems(items: BreadcrumbItem[]): Promise<void> {
    this.ADD_ITEMS(items)
  }

  @mutation
  RESET_ITEMS(): void {
    this.addedItems.splice(0, this.addedItems.length)
  }

  @action
  async resetItems(): Promise<void> {
    this.RESET_ITEMS()
  }

  get items(): BreadcrumbItem[] {
    return this.matchedItems.concat(this.addedItems)
  }
}
