import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { inject, injectable, unmanaged } from 'inversify'
import { Permission, User } from '~/schemes/auth/keycloak'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import type { Vxm } from '~/types/portal'

export class MissingServiceIdError extends Error {}
export class MissingGroupIdError extends Error {}
@injectable()
export class PermissionService {
  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm,
    @unmanaged() public serviceId?: string,
    @unmanaged() public defaultGroup?: number
  ) {}

  public isAllowed(
    companyId: string,
    ownPermission: Permission,
    clusterPermission: Permission,
    allPermission: Permission,
    serviceId?: string,
    groupId?: number
  ): boolean {
    serviceId = this.getServiceId(serviceId)
    groupId = this.getGroupId(groupId)

    const mask = this.getMask(serviceId, groupId)
    const inCompany = this.getUser().company === companyId
    const inCluster =
      this.vxm.users.userInfo?.cluster.some((c) => c.id === companyId) ?? false

    if (!inCompany && !inCluster) {
      return (mask & allPermission) > 0
    }

    if (inCompany && !inCluster) {
      return (mask & (allPermission | ownPermission)) > 0
    }

    if (!inCompany && inCluster) {
      return (mask & (allPermission | clusterPermission)) > 0
    }

    return (mask & (allPermission | clusterPermission | ownPermission)) > 0
  }

  protected getMask(): Permission
  protected getMask(serviceId: string, groupId: number): Permission
  protected getMask(serviceId?: string, groupId?: number): Permission

  protected getMask(serviceId?: string, groupId?: number): Permission {
    if (!this.auth.loggedIn) {
      return 0
    }
    const permissions = this.getUser().permissions

    serviceId = this.getServiceId(serviceId)
    groupId = this.getGroupId(groupId)

    return permissions[serviceId]?.[groupId] ?? 0
  }

  public isAnythingAllowed(): boolean {
    return this.getMask() > 0
  }

  public hasPermission(permission: Permission): boolean
  public hasPermission(permission: Permission, groupId: number): boolean
  public hasPermission(
    permission: Permission,
    groupId: number,
    serviceId: string
  ): boolean

  public hasPermission(
    permission: Permission,
    groupId?: number,
    serviceId?: string
  ): boolean {
    return (this.getMask(serviceId, groupId) & permission) > 0
  }

  protected getUser(): User {
    if (!this.auth.loggedIn) {
      throw new Error('No User. Must be logged in.')
    }
    return this.auth.user as User
  }

  protected getServiceId(serviceId?: string): string {
    serviceId = serviceId ?? this.serviceId

    if (serviceId === undefined) {
      throw new MissingServiceIdError()
    }

    return serviceId
  }

  protected getGroupId(groupId?: number): number {
    groupId = groupId ?? this.defaultGroup

    if (groupId === undefined) {
      throw new MissingGroupIdError()
    }

    return groupId
  }
}
