
import { defineComponent } from '@nuxtjs/composition-api'
import Avatar from 'vue-avatar'

const getInitials = (string: string) => {
  const username = string.split('@')[0]
  const parts = username.split(/[ -.]/)
  let initials = ''

  parts.forEach((part) => {
    initials += part.charAt(0)
  })

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.substr(0, 3).toUpperCase()

  return initials
}

export default defineComponent({
  name: 'PAvatar',
  extends: Avatar,
  props: {
    parser: {
      type: Function,
      default: getInitials,
    },
  },
})
