import type {
  BasePortalConfig,
  NavigationItemConfig,
} from '~/types/portal-config'
import { InternalNavigationItem } from '~/store/portalConfig'
import { createPermissionObject } from '~/helpers/config/createPermissionObject'
import untypedBaseConfig from '~/static/base-portal-config.json'

export const basePortalConfig = untypedBaseConfig as BasePortalConfig

export function prepareNavigation(
  fetchedNavigation: NavigationItemConfig[],
  staticNavPermissionService: string,
  navPermissionService: string
) {
  const staticNavigationItems = basePortalConfig.navigation

  const internalNavigation: InternalNavigationItem[] = []

  staticNavigationItems.forEach((item) => {
    internalNavigation.push({
      ...item,
      permission: createPermissionObject(
        staticNavPermissionService,
        item.permission
      ),
    })
  })

  fetchedNavigation.forEach((item) => {
    internalNavigation.push({
      ...item,
      permission: createPermissionObject(navPermissionService, item.permission),
    })
  })

  return internalNavigation
}
