import { inject, injectable } from 'inversify'
import { AxiosInstance } from 'axios'
import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'

export type Contact = {
  title: string | null
  firsName: string
  lastName: string
  profileImage: string | null
  phone: string | null
  email: string
  department: string
  company: string
  mobilePhone: string | null
  jobTitle: string
}

@injectable()
export default class ContactsApiService {
  public constructor(
    @inject(SYMBOLS.Axios) private readonly axios: AxiosInstance,
    @inject(SYMBOLS.Auth) private readonly auth: Auth
  ) {}

  public async list(): Promise<Contact[]> {
    const result = await this.axios.get<Contact[]>(
      `company/${this.auth.user?.company}/contacts`
    )

    return result.data
  }
}
