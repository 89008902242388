
import { defineComponent, PropType } from '@vue/composition-api'
import type { RawLocation } from 'vue-router'

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
})
