import { useFileApiService } from '~/src/composables/dependency'

function readAsDataUrl(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (_e) => resolve(reader.result as string)
    reader.onerror = (_e) => reject(reader.error)
    reader.onabort = (_e) => reject(new Error('Read aborted'))
    reader.readAsDataURL(blob)
  })
}

export async function fileIdToDataUrl(fileId: string): Promise<string> {
  const fileApiService = useFileApiService()
  return readAsDataUrl(await fileApiService.download(fileId))
}
