import { OrderBy, TableOrderBy, TableSortingKey } from '~/types/portal'

export function tableToApiOrder<
  T extends string = string,
  K extends TableSortingKey<T> = TableSortingKey<T>
>(orderBy: TableOrderBy<K>): OrderBy<T> | undefined {
  if (orderBy === undefined) {
    return undefined
  }

  const result: OrderBy<T> = {}

  if (Array.isArray(orderBy.key)) {
    for (const key of orderBy.key) {
      result[key as T] = orderBy.order
    }
  } else {
    result[orderBy.key as T] = orderBy.order
  }

  return result
}
