import type { InternalNavigationItem } from '~/store/portalConfig'
import { usePathMapping } from '~/src/composables/usePathMapping'

/**
 * Check whether the currently logged-in user may access
 * the given `navigationItem`. Uses portalConfigStore, typically
 * fetched from viewport-assets.
 *
 * @param navigationItem - Navigation item for which to check
 * @returns whether the logged-in user is permitted to access the given navigationItem
 */
export function checkNavigationItemPermission(
  navigationItem: InternalNavigationItem
): boolean {
  const { extractPathFromRawLocation, checkPermission } = usePathMapping()

  const target = extractPathFromRawLocation(navigationItem.target)

  if (!target) {
    return true
  }

  return checkPermission(target)
}
