import { diff } from 'deep-object-diff'
import { pick } from 'lodash'
import { action, createModule, mutation } from 'vuex-class-component'
import { useConfigurationApiService } from '~/src/composables/dependency'
import type {
  DesignConfigDto,
  DesignConfigInterface,
} from '~/types/DesignConfig'
import { Color, DesignConfigImage } from '~/types/DesignConfig'

const VuexModule = createModule({
  namespaced: 'configuration',
  strict: false,
  target: 'nuxt',
})

export class ConfigurationStore extends VuexModule {
  configuration: DesignConfigInterface | null = null
  fetched = false

  @mutation
  SET_CONFIGURATION(configuration: DesignConfigInterface): void {
    this.configuration = { ...configuration }
  }

  @mutation
  SET_FETCHED(): void {
    this.fetched = true
  }

  @action
  async fetchConfiguration(apiUrl: string): Promise<void> {
    if (this.fetched) {
      return
    }

    const response: DesignConfigDto = await (
      await fetch(apiUrl + '/configuration')
    ).json()
    const model: DesignConfigInterface = {}

    if (response.logo?.mobile && response.logo?.desktop) {
      model.logo = new DesignConfigImage(
        response.logo.mobile,
        response.logo.desktop
      )
    }

    if (response.loginBackground?.mobile && response.loginBackground?.desktop) {
      model.loginBackground = new DesignConfigImage(
        response.loginBackground?.mobile,
        response.loginBackground?.desktop
      )
    }

    if (
      response.portalBackground?.mobile &&
      response.portalBackground?.desktop
    ) {
      model.portalBackground = new DesignConfigImage(
        response.portalBackground?.mobile,
        response.portalBackground?.desktop
      )
    }

    if (response.primaryColor !== undefined) {
      model.primaryColor = Color.fromArray(response.primaryColor)
    }

    if (response.secondaryColor !== undefined) {
      model.secondaryColor = Color.fromArray(response.secondaryColor)
    }

    if (response.loginLogoBgColor !== undefined) {
      model.loginLogoBgColor = Color.fromArray(response.loginLogoBgColor)
    }

    if (response.loginLogo?.mobile && response.loginLogo?.desktop) {
      model.loginLogo = new DesignConfigImage(
        response.loginLogo?.mobile,
        response.loginLogo?.desktop
      )
    }

    this.SET_CONFIGURATION(model)
    this.SET_FETCHED()
  }

  @action
  async updateConfiguration(
    newConfiguration: DesignConfigInterface
  ): Promise<void> {
    const configDiff =
      this.configuration !== null
        ? pick(
            newConfiguration,
            Object.keys(diff(this.configuration, newConfiguration))
          )
        : newConfiguration

    const apiService = useConfigurationApiService()

    await apiService.update(configDiff)
    this.SET_CONFIGURATION(newConfiguration)
  }
}
