import { onGlobalSetup, provide } from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { Container } from '~/src/dependency_injection/Container'
import { container, useVxm } from '~/src/composables/dependency'

declare module '@nuxt/types' {
  interface Context {
    $dic: Container
  }
}

export default (ctx: Context, inject: Inject): void => {
  const vxm = useVxm()

  container.setAxios(ctx.$axios)
  container.setFuchsbauId(vxm.portalConfig?.fuchsbauId)
  container.setTranslator(ctx.i18n)
  container.setNavPermissionService(ctx.$config.NavPermissionService)
  container.setStaticNavPermissionService(
    ctx.$config.StaticNavPermissionService
  )
  inject('dic', container)
  onGlobalSetup(() => {
    provide('dic', container)
  })
}
