import { Context } from '@nuxt/types'
import type { LocaleObject } from 'nuxt-i18n'
import { watch } from '@nuxtjs/composition-api'
import { User } from '~/schemes/auth/keycloak'
import { assertLocalObjectArray } from '~/src/functions/locales'

export default function ({ $auth, i18n }: Context): void {
  watch(
    () => $auth.user,
    (newValue: unknown) => {
      const user = newValue as User

      let language: string =
        user?.language ?? i18n.getBrowserLocale() ?? i18n.defaultLocale

      const locales = i18n.locales
      assertLocalObjectArray(locales)

      const languageProperties = locales.find(
        (locale: LocaleObject) => locale.code === language
      )

      if (languageProperties?.enabled !== true) {
        language = i18n.defaultLocale
      }

      i18n.setLocale(language)
    },
    { immediate: true }
  )
}
