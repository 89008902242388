import { AxiosError, AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import SYMBOLS from '../dependency_injection/Symbols'
import { ListResponse } from './types/ListResponse'
import { TableOrderBy } from '~/types/portal'
import Company from '~/src/models/Company'
import AbstractApiService, {
  NotAllowedException,
} from '~/src/services/AbstractApiService'
import { tableToApiOrder } from '~/helpers/tableToApiOrder'
import serializeParams from '~/helpers/serializeParams'

export type CompanyId = string
export type RegistrationToken = string

type RegistrationTokenResponseBody = {
  registerToken: RegistrationToken
}

type CompanyDataByRegistrationTokenResponseBody = {
  id: CompanyId
  name: string
}

type CompanyDataByRegistrationToken = CompanyDataByRegistrationTokenResponseBody

export type CompanySortingKeys = 'name'

export interface SearchParameters {
  page?: number
  order?: TableOrderBy<CompanySortingKeys>
  hasParent?: boolean
  search?: string
}

export class CompanyNotFoundError extends Error {
  public constructor(company: CompanyId) {
    super(`Company "${company}" not found`)
  }
}

export class RegistrationTokenNotFoundError extends Error {
  public constructor(token: RegistrationToken) {
    super(`Registration token "${token}" not found`)
  }
}
@injectable()
export default class CompanyApiService extends AbstractApiService<
  Company,
  TableOrderBy<CompanySortingKeys>
> {
  constructor(@inject(SYMBOLS.Axios) axios: AxiosInstance) {
    super(axios, 'company')
  }

  public async search(
    params: SearchParameters = {}
  ): Promise<ListResponse<Company[]>> {
    try {
      const requestParams: Record<string, unknown> = {
        page: params.page,
        has_parent: params.hasParent,
      }

      if (params.order) {
        requestParams.order = tableToApiOrder(params.order)
      }

      if (params.search) {
        requestParams.search = params.search
      }

      const response = await this.axios.get<ListResponse<Company[]>>(
        '/company',
        {
          params: requestParams,
          paramsSerializer: serializeParams,
        }
      )

      return response.data
    } catch (e: unknown) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }

      throw e
    }
  }

  public async getById(id: string): Promise<Company> {
    try {
      const response = await this.axios.get<Company>('/company/' + id)

      return response.data
    } catch (e: unknown) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }

      throw e
    }
  }

  public async exchangeCompanyIdForRegistrationToken(
    company: CompanyId
  ): Promise<RegistrationToken> {
    try {
      const response = await this.axios.get<RegistrationTokenResponseBody>(
        `/company/${company}/register-token`
      )
      return response.data.registerToken
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 404) {
        throw new CompanyNotFoundError(company)
      }
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }

      throw e
    }
  }

  public async exchangeRegistrationTokenForCompanyData(
    token: RegistrationToken
  ): Promise<CompanyDataByRegistrationToken> {
    try {
      const response =
        await this.axios.get<CompanyDataByRegistrationTokenResponseBody>(
          `/register-token/${token}`
        )
      return response.data
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 404) {
        throw new RegistrationTokenNotFoundError(token)
      }

      throw e
    }
  }
}
