import matchBreadcrumb from './matchBreadcrumb'
import { BreadcrumbConfigItem, BreadcrumbConfigRecord } from '~/types/portal'

const extendBreadcrumb = (
  path: string,
  item: BreadcrumbConfigItem,
  config: BreadcrumbConfigRecord
): BreadcrumbConfigRecord => {
  let result: BreadcrumbConfigRecord = {}

  const [match, restPath] = matchBreadcrumb(path, config)

  if (match !== undefined) {
    const [matchedPath, subConfig] = match

    let newSubConfig: BreadcrumbConfigItem

    if (typeof subConfig === 'string') {
      newSubConfig = { label: subConfig, children: {} }
    } else {
      newSubConfig = { ...subConfig }
    }

    if (restPath.length === 0) {
      result = {
        ...config,
        [matchedPath]: {
          ...newSubConfig,
          children: {
            ...newSubConfig.children,
            [restPath]: item,
          },
        },
      }
    } else {
      result = {
        ...config,
        [matchedPath]: {
          ...newSubConfig,
          children: {
            ...extendBreadcrumb(restPath, item, newSubConfig.children ?? {}),
          },
        },
      }
    }
  } else {
    result = {
      ...config,
      [path]: item,
    }
  }

  return result
}

export default extendBreadcrumb
