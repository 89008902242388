





























import { defineComponent } from '@nuxtjs/composition-api'
import { PropType } from '@vue/composition-api'
import { DesignConfigImage } from '~/types/DesignConfig'

export default defineComponent({
  name: 'PBackground',
  props: {
    background: {
      type: [Object, null] as PropType<DesignConfigImage | null>,
      default: undefined,
    },
    backgroundMobileDefault: {
      type: [String] as PropType<string>,
      default: '/bg-portal-mobile.jpg',
    },
    backgroundDesktopDefault: {
      type: [String] as PropType<string>,
      default: '/bg-portal-desktop.jpg',
    },
  },
})
