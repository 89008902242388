import { parsePermission } from '~/helpers/config/parsePermission'

export interface PermissionGroupAndBit {
  group: number
  bit: number
}

/**
 * Returns the group and the bit of a given permission limited by `limit`
 * If `permission` is greater than `limit`, it overflows into the
 * next group.
 * Group is 1-indexed.
 * Limit defaults to 32.
 *
 * @param permission
 * @param limit
 * @example
 * getGroupAndBit(0) // { group: 1, permission: 1 }
 * getGroupAndBit(1) // { group: 1, permission: 2 }
 * getGroupAndBit(16) // { group: 1, permission: 65536 }
 * getGroupAndBit(32) // { group: 2, permission: 1 }
 */
export function getGroupAndBit(
  permission: number,
  limit = 32
): PermissionGroupAndBit {
  const group = Math.floor(permission / limit) + 1
  const bit = permission % limit
  return { group, bit: parsePermission(bit) }
}
