
import {
  computed,
  defineComponent,
  useRouter,
  watch,
} from '@nuxtjs/composition-api'
import { useVxm } from '~/src/composables/dependency'
import {
  isMaxLg,
  isMaxMd,
  isMaxSm,
  isMinLg,
  isMinXl,
} from '~/helpers/breakpointProvider'
import { createNavigationItems } from '~/helpers/navigation/createNavigationItems'
import MainNavigationGroup from '~/components/navigation/MainNavigationGroup.vue'
import { groupNavigationItems } from '~/helpers/navigation/groupNavigationItems'

export default defineComponent({
  name: 'PMainNavigation',
  components: { MainNavigationGroup },
  setup() {
    const { navigation, portalConfig } = useVxm()

    const navigationGroups = computed(() => {
      const items = createNavigationItems(portalConfig.config?.navigation ?? [])
      return groupNavigationItems(items, {
        root: {
          showDivider: false,
        },
        admin: {
          label: 'navigation.divider.admin',
          showDivider: true,
        },
        default: {
          label: 'navigation.divider.applications',
          showDivider: true,
        },
      })
    })

    const isOpen = computed(() => navigation.isMainOpen)
    const isExtended = computed({
      get: () => navigation.isMainExtended,
      set: (isExtended: boolean) => navigation.setIsMainExtended(isExtended),
    })

    const toggle = () => {
      isExtended.value = !isExtended.value
    }

    function close() {
      navigation.closeMain()
    }

    watch(
      [isMaxMd, isMinXl],
      ([isMaxMd, isMinXl]) => {
        isExtended.value = isMaxMd || isMinXl
      },
      { immediate: true }
    )

    const router = useRouter()

    router.afterEach(() => {
      if (isMaxLg.value) {
        close()

        if (isMinLg.value) {
          isExtended.value = false
        }
      }

      if (isOpen.value && isMaxSm.value) {
        document.body.style.overflow = 'hidden'
        document.body.style.touchAction = 'none'
      } else {
        document.body.style.overflow = ''
        document.body.style.touchAction = ''
      }
    })

    return {
      isOpen,
      isExtended,
      isMinLg,
      isMaxMd,
      navigationGroups,
      toggle,
    }
  },
})
