export interface OwnerCompany {
  id: string
  name: string
}

export type OwnerUser = {
  id: string
  firstName: string
  lastName: string
  deleted: boolean
}

export type Owner = {
  company: OwnerCompany
  user: OwnerUser
}

export interface DocumentRelation {
  domain: string
  subject: string
}

export enum DocumentAccessibility {
  EVERYONE = 'nobody',
  SELECTED_COMPANIES = 'companies',
  SELECTED_USERS = 'users',
  SELECTED_GROUPS = 'groups',
  JUST_ME = 'everyone',
}

export type FileId = string

export interface FileMeta {
  id: FileId
  name: string
  type: string
  size: number
}

export type AccessibleByCompanies = {
  to: DocumentAccessibility.SELECTED_COMPANIES
  restrictTo: string[]
}

export type AccessibleByGroups = {
  to: DocumentAccessibility.SELECTED_GROUPS
  restrictTo: string[]
}

export type AccessibleByUsers = {
  to: DocumentAccessibility.SELECTED_USERS
  restrictTo: string[]
}

export type AccessibleByEveryone = {
  to: DocumentAccessibility.EVERYONE
}

export type AccessibleByMe = {
  to: DocumentAccessibility.JUST_ME
}

export type SharedAccess =
  | AccessibleByCompanies
  | AccessibleByGroups
  | AccessibleByUsers
  | AccessibleByEveryone
  | AccessibleByMe

export interface HasId {
  id: string
}

interface Favorable {
  favorite: boolean
}

export interface HasFileName {
  name: string
}

export interface HasCreationDate {
  created: string
}

export interface HasFileId {
  file: FileId
}

export interface HasFileMeta {
  file: FileMeta
}

export interface HasFile {
  file: File
}

export interface HasModuleRestrictions {
  restrictedTo: DocumentAccessibility
  restrictions?: string[]
}

export interface HasRestrictions {
  restrictAccess: SharedAccess
}

export interface HasRelations {
  relations: DocumentRelation[]
}

export interface HasOwner {
  owner: Owner
}

export interface DocumentInterface {
  id?: string
  name: string
  content?: string
  type: string
  size: number
  created?: string
  owner?: Owner
  restrictedTo?: DocumentAccessibility
  restrictions?: string[]
  relations?: DocumentRelation[]
}

export type DocumentWithId = DocumentInterface &
  Required<Pick<DocumentInterface, 'id'>>

export interface ListableDocument
  extends HasId,
    HasCreationDate,
    HasFileMeta,
    HasRestrictions,
    HasRelations,
    Favorable,
    HasOwner {}

export interface CreatableDocument
  extends HasFile,
    HasRestrictions,
    HasRelations {}

export interface CreatableModuleDocument
  extends HasFile,
    HasModuleRestrictions,
    HasRelations {}

export type UpdatableDocument = HasRestrictions

export class DocumentFactory {
  produceOwner({
    company = {
      id: '',
      name: '',
    },
    user = {
      id: '',
      firstName: '',
      lastName: '',
      deleted: false,
    },
  }: Partial<Owner>): Owner {
    return {
      company,
      user,
    }
  }

  produceFileMeta({
    id = '',
    name = '',
    type = '',
    size = 0,
  }: Partial<FileMeta>): FileMeta {
    return {
      id,
      name,
      type,
      size,
    }
  }

  produceCreatable({
    restrictAccess = {
      to: DocumentAccessibility.JUST_ME,
    },
    file = new File([], ''),
    relations = [],
  }: Partial<CreatableDocument>): CreatableDocument {
    return {
      restrictAccess: {
        ...restrictAccess,
      },
      file,
      relations,
    }
  }
}
