import * as gtag from 'vue-gtag'
import type { Plugin } from '@nuxt/types'
import * as leadinfo from '~/plugins/tracking/leadinfo'
import * as postHog from '~/plugins/tracking/postHog'
import { useVxm } from '~/src/composables/dependency'

const plugin: Plugin = () => {
  const vxm = useVxm()
  const cmpData = __cmp('getCMPData')
  const config = vxm.portalConfig.config

  const googleAnalyticsConfig = config?.tracking.googleAnalytics?.config
  const leadInfoConfig = config?.tracking.leadInfo?.config
  const postHogConfig = config?.tracking.postHog?.config

  let leadInfoEnabled = cmpData.vendorConsents.s2688
  let postHogEnabled = cmpData.vendorConsents.s2688

  function handleCmpInit(..._: unknown[]) {
    const cmpData = __cmp('getCMPData')

    if (googleAnalyticsConfig?.enabled) {
      cmpData.vendorConsents.s905 ? gtag.optIn() : gtag.optOut()
    }

    if (leadInfoConfig?.enabled) {
      if (cmpData.vendorConsents.s2688) {
        leadinfo.optIn(leadInfoConfig.siteId)
        leadInfoEnabled = cmpData.vendorConsents.s2688
      } else if (leadInfoEnabled !== cmpData.vendorConsents.s2688) {
        leadinfo.optOut()
      }
    }

    if (postHogConfig?.enabled) {
      if (cmpData.vendorConsents.c63034) {
        postHog.optIn(postHogConfig.apiKey)
        postHogEnabled = cmpData.vendorConsents.c63034
      } else if (postHogEnabled !== cmpData.vendorConsents.c63034) {
        postHog.optOut()
      }
    }
  }

  __cmp('addEventListener', ['consent', handleCmpInit, false], null)
  handleCmpInit()
}

export default plugin
