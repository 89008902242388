import { Plugin } from '@nuxt/types'
import { createProxy } from 'vuex-class-component'
import { container } from '~/src/composables/dependency'
import { BreadcrumbStore } from '~/store/breadcrumb'
import { ConfigurationStore } from '~/store/configuration'
import { LoadingStore } from '~/store/loading'
import { PermissionStore } from '~/store/permissions'
import { PortalConfigStore } from '~/store/portalConfig'
import { RoleStore } from '~/store/roles'
import { UserStore } from '~/store/users'
import { AccountAttributesStore } from '~/store/accountAttributes'
import { NavigationStore } from '~/store/navigation'
import { CompanyStore } from '~/store/companies'

const storeProxies: Plugin = (context) => {
  const vxm = {
    roles: createProxy(context.store, RoleStore),
    users: createProxy(context.store, UserStore),
    permissions: createProxy(context.store, PermissionStore),
    configuration: createProxy(context.store, ConfigurationStore),
    breadcrumb: createProxy(context.store, BreadcrumbStore),
    loading: createProxy(context.store, LoadingStore),
    accountAttributes: createProxy(context.store, AccountAttributesStore),
    portalConfig: createProxy(context.store, PortalConfigStore),
    navigation: createProxy(context.store, NavigationStore),
    companies: createProxy(context.store, CompanyStore),
  }

  container.setVxm(vxm)
}

export default storeProxies
