import { inject, injectable } from 'inversify'
import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { NavPermissionsService } from './NavPermissionsService'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'
import Permission from '~/src/services/permissions/types/Permission'
import type { Vxm } from '~/types/portal'

@injectable()
export class DocumentPermissionsService extends PermissionService {
  public static DEFAULT_GROUP_PERMISSIONS = {
    DELETE_DOCUMENTS_IN_SAME_COMPANY: 1,
    DELETE_DOCUMENTS_IN_ALL_COMPANIES: 2,
    CREATE: 4,
    UPDATE_DOCUMENTS_IN_SAME_COMPANY: 8,
    UPDATE_DOCUMENTS_IN_ALL_COMPANIES: 16,
  }

  private navPermission: Permission

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm,
    @inject(NavPermissionsService)
    navPermissionService: NavPermissionsService
  ) {
    super(auth, vxm, 'hSUf3g', 1)
    this.navPermission = navPermissionService.generateStaticNavPermission(16)
  }

  public isReadAllowed(): boolean {
    return this.navPermission.isAllowed()
  }

  public isCreateNewAllowed(): boolean {
    return this.isAllowed(
      '',
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS.CREATE,
      0,
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS.CREATE
    )
  }

  public isDeleteAllowed(ownerUserId: string, ownerCompanyId: string): boolean {
    if (this.getUser().id === ownerUserId) {
      return true
    }

    return this.isAllowed(
      ownerCompanyId,
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS
        .DELETE_DOCUMENTS_IN_SAME_COMPANY,
      0,
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS
        .DELETE_DOCUMENTS_IN_ALL_COMPANIES
    )
  }

  public isUpdateAllowed(ownerUserId: string, ownerCompanyId: string): boolean {
    if (this.getUser().id === ownerUserId) {
      return true
    }

    return this.isAllowed(
      ownerCompanyId,
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS
        .UPDATE_DOCUMENTS_IN_SAME_COMPANY,
      0,
      DocumentPermissionsService.DEFAULT_GROUP_PERMISSIONS
        .UPDATE_DOCUMENTS_IN_ALL_COMPANIES
    )
  }
}
