// @ts-nocheck

import { decode } from 'jsonwebtoken'
import { HTTPResponse } from '@nuxtjs/auth-next'
// @ts-ignore
import { OpenIDConnectScheme } from '~auth/runtime'
import { transformTokenData } from '~/schemes/auth/common'
import 'navigator.locks'


function encodeQuery(queryObject) {
  return Object.entries(queryObject).filter(([_key, value]) => typeof value !== "undefined").map(([key, value]) => encodeURIComponent(key) + (value != null ? "=" + encodeURIComponent(value) : "")).join("&");
}

function parseQuery(queryString) {
  const query = {}
  const pairs = queryString.split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

function normalizePath(path = '', ctx) {
  let result = path.split('?')[0]
  if (ctx && ctx.base) {
    result = result.replace(ctx.base, '/')
  }
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }
  result = result.replace(/\/+/g, '/')
  return result
}

function getProp(holder, propName) {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }
  if (propName in holder) {
    return holder[propName]
  }
  const propParts = Array.isArray(propName)
    ? propName
    : (propName + '').split('.')
  let result = holder
  while (propParts.length && result) {
    result = result[propParts.shift()]
  }
  return result
}

export default class Oidc extends OpenIDConnectScheme {
  updateTokens(response: unknown) {
    super.updateTokens(response)
    this.setUser()
  }

  async refreshTokens(): Promise<HTTPResponse | void> {
    return await this.lock(async () => await super.refreshTokens())
  }

  mounted() {
    const { $authUrl } = this.$auth.ctx

    this.options.endpoints.configuration = `${$authUrl}/auth/realms/kundenportal/.well-known/openid-configuration`

    return super.mounted()
  }

  lock(
    callback: () => Promise<HTTPResponse | void>
  ): Promise<HTTPResponse | void> {
    if (!process.client) {
      return Promise.resolve()
    }
    return window.navigator.locks.request('auth-refresh', callback)
  }

  fetchUser() {
    // @ts-ignore
    if (!this.check().valid) {
      return Promise.resolve()
    }

    this.setUser()

    return Promise.resolve()
  }

  setUser() {
    const token = this.getDecodedToken()

    // @ts-ignore
    this.$auth.setUser(transformTokenData(token))
  }

  getDecodedToken() {
    // @ts-ignore
    return decode(this.token.get()?.replace(/^Bearer /, ''))
  }

  async _handleCallback() {
    if (
      this.$auth.options.redirect &&
      normalizePath(this.$auth.ctx.route.path) !==
        normalizePath(this.$auth.options.redirect.callback)
    ) {
      return
    }
    if (process.server) {
      return
    }
    const hash = parseQuery(this.$auth.ctx.route.hash.substr(1))
    const parsedQuery = Object.assign({}, this.$auth.ctx.route.query, hash)
    let token = parsedQuery[this.options.token.property]
    let refreshToken
    if (this.options.refreshToken.property) {
      refreshToken = parsedQuery[this.options.refreshToken.property]
    }
    let idToken = parsedQuery[this.options.idToken.property]
    const state = this.$auth.$storage.getUniversal(this.name + '.state')
    this.$auth.$storage.setUniversal(this.name + '.state', null)
    if (state && parsedQuery.state !== state) {
      return
    }
    if (this.options.responseType === 'code' && parsedQuery.code) {
      let codeVerifier
      if (
        this.options.codeChallengeMethod &&
        this.options.codeChallengeMethod !== 'implicit'
      ) {
        codeVerifier = this.$auth.$storage.getUniversal(
          this.name + '.pkce_code_verifier'
        )
        this.$auth.$storage.setUniversal(
          this.name + '.pkce_code_verifier',
          null
        )
      }
      const response = await this.$auth.request({
        method: 'post',
        url: this.options.endpoints.token,
        baseURL: '',
        data: encodeQuery({
          code: parsedQuery.code,
          client_id: this.options.clientId,
          redirect_uri: this.redirectURI,
          response_type: this.options.responseType,
          audience: this.options.audience,
          grant_type: this.options.grantType,
          code_verifier: codeVerifier,
        }),
      })
      token = getProp(response.data, this.options.token.property) || token
      refreshToken =
        getProp(response.data, this.options.refreshToken.property) ||
        refreshToken
      idToken = getProp(response.data, this.options.idToken.property) || idToken
    }
    if (!token || !token.length) {
      return
    }
    this.token.set(token)

    if (refreshToken && refreshToken.length) {
      this.refreshToken.set(refreshToken)
    }

    if (idToken && idToken.length) {
      this.idToken.set(idToken)
    }

    await this.$auth.requestWith(this.name, {
      url: '/initialize-user',
    })

    this.$auth.redirect('home', true)
    return true
  }
}
