import { action, createModule, mutation } from 'vuex-class-component'
import type { Company } from '@viewport/library-next'
import UserApiService, {
  User,
  UserId,
  UserInfoResponse,
} from '~/src/services/UserApiService'

const VuexModule = createModule({
  namespaced: 'users',
  strict: false,
  target: 'nuxt',
})

const SELECTED_COMPANY_KEY = 'selectedCompany'

export class UserStore extends VuexModule {
  loggedIn: User | null = null
  loggedInId: UserId | null = null
  userInfo: UserInfoResponse | null = null
  selectedCompany: Company | null = null

  @mutation
  setLoggedIn({ id, user }: { id: UserId | null; user: User | null }): void {
    this.loggedInId = id
    this.loggedIn = user
  }

  @mutation
  setUserInfo(userInfo: UserInfoResponse): void {
    this.userInfo = userInfo
  }

  @mutation
  setSelectedCompany(company: Company): void {
    this.selectedCompany = company
    sessionStorage.setItem(SELECTED_COMPANY_KEY, JSON.stringify(company))
  }

  @action
  async fetchLoggedIn({
    id,
    userApiService,
    ignoreExisting = false,
  }: {
    id: UserId | null
    userApiService: UserApiService
    ignoreExisting?: boolean
  }): Promise<void> {
    if (id === null) {
      this.setLoggedIn({ id: null, user: null })
      return
    }

    if (!ignoreExisting && this.loggedInId === id) {
      return
    }

    const userPromise = userApiService.get(id)
    const userInfoPromise = userApiService.info(id)

    const user = await userPromise

    this.setLoggedIn({
      id,
      user,
    })

    if (user !== null) {
      await this.selectCompany({
        id: user.companyId,
        name: user.company,
      })
    }

    const userInfo = await userInfoPromise
    this.setUserInfo(userInfo)
  }

  @action
  selectCompany(company: Company): Promise<void> {
    this.setSelectedCompany(company)
    return Promise.resolve()
  }
}
