import type { Context } from '@nuxt/types'
import { ConstraintsViolatedError } from '~/src/models/ConstraintsViolatedError'
import type { UnprocessableEntityResponseBody } from '~/types/UnprocessableEntityResponseBody'

export default function ({ $axios }: Context): void {
  $axios.onError((error) => {
    if (error.response?.status === 422) {
      return Promise.reject(
        new ConstraintsViolatedError(
          error.response.data as UnprocessableEntityResponseBody
        )
      )
    }

    return Promise.reject(error)
  })
}
