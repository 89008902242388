import { inject, injectable } from 'inversify'
import { AxiosError, AxiosInstance } from 'axios'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import { ConstraintsViolatedError } from '~/src/models/ConstraintsViolatedError'

export class ResetPasswordTokenNotFoundError extends Error {}

@injectable()
export default class ResetPasswordApiService {
  public constructor(@inject(SYMBOLS.Axios) private axios: AxiosInstance) {}

  public async postRequest(email: string): Promise<void> {
    await this.axios.post('/password-reset', { email })
  }

  public async get(token: string): Promise<void> {
    try {
      await this.axios.get('/password-reset/' + token)
    } catch (e: unknown) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new ResetPasswordTokenNotFoundError()
      }

      throw e
    }
  }

  public async post(
    token: string,
    newPassword: string
  ): Promise<void | boolean> {
    const data = {
      password: newPassword,
    }

    try {
      await this.axios.post('/password-reset/' + token, data)
    } catch (e: unknown) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new ResetPasswordTokenNotFoundError()
      } else if (status === 422) {
        throw new ConstraintsViolatedError((e as AxiosError).response?.data)
      }

      throw e
    }
  }
}
