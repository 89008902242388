import axios, { AxiosError, AxiosInstance } from 'axios'
import { diff } from 'deep-object-diff'
import { inject, injectable } from 'inversify'
import { pick } from 'lodash'
import SYMBOLS from '../dependency_injection/Symbols'
import { ListResponse } from './types/ListResponse'
import FileApiService from './FileApiService'
import { tableToApiOrder } from '~/helpers/tableToApiOrder'
import {
  CreatableDocument,
  CreatableModuleDocument,
  DocumentAccessibility,
  HasFileId,
  HasFileMeta,
  HasFileName,
  HasRelations,
  HasRestrictions,
  ListableDocument,
  SharedAccess,
  UpdatableDocument,
} from '~/src/models/Document'
import { NotAllowedException } from '~/src/services/AbstractApiService'
import { CompanyId } from '~/src/services/CompanyApiService'
import { GroupId } from '~/src/services/GroupApiService'
import { UserId } from '~/src/services/UserApiService'
import { TableOrderBy } from '~/types/portal'
import serializeParams from '~/helpers/serializeParams'

export type DocumentSortingKeys = 'created' | 'name'

export interface IdAndName {
  id: UserId | GroupId | CompanyId
  name: string
}

export type DocumentListResponse = ListResponse<ListableDocument[]>
export interface DocumentCreateRequest
  extends HasFileName,
    HasFileId,
    HasRestrictions,
    HasRelations {}

@injectable()
export default class DocumentsApiService {
  public constructor(
    @inject(SYMBOLS.Axios) private axios: AxiosInstance,
    @inject(FileApiService) private fileApiService: FileApiService
  ) {}

  /**
   * Retrieves a single document using `GET /document/${id}`.
   *
   * @async
   * @param {string} id - the document's ID
   * @returns {Promise<ListableDocument>} the document
   */
  public async get(id: string): Promise<ListableDocument> {
    const response = await this.axios.get<ListableDocument>(`document/${id}`)

    return response.data
  }

  /**
   * Retrieves document file content and type using `GET /file/${id}`.
   * Determines the id from the given document, which may either implement
   * `HasFileId` or `HasFileMeta`.
   *
   * A convenience method for the alternative of using the `FileApiService`
   * and passing the file's ID directly.
   *
   * Uses the `FileApiService` internally.
   *
   * @async
   * @param {HasFileId | HasFileMeta} document - of which to retrieve the file
   * @returns {Promise<Blob>} the file as a Blob
   */
  public getContent(document: HasFileId | HasFileMeta): Promise<Blob> {
    let fileId: string

    if (typeof document.file === 'string') {
      // document is of type HasFileId, `file` is the id
      fileId = document.file
    } else {
      // document is of type HasFileMeta, `file.id` is the id
      fileId = document.file.id
    }

    return this.fileApiService.download(fileId)
  }

  /**
   * Gets a document's content using its ID.
   * Exists for downwards compatibility.
   * You may use {@link #getContent} instead.
   *
   * Uses {@link #getContent} internally.
   *
   * @async
   * @param id - the document's ID
   * @returns the file associated with the document
   */
  public async getContentUsingDocumentId(id: string): Promise<Blob> {
    const fullDocument = await this.get(id)

    return this.getContent(fullDocument)
  }

  /**
   * Calls upon `GET /document` to retrieve a list of documents.
   *
   * @async
   * @param page - which page to retrieve
   * @param [order] - in which order to sort documents
   * @param favorites
   * @returns A list of documents as well as the number of pages
   */
  public async list(
    page: number,
    order?: TableOrderBy<DocumentSortingKeys>,
    favorites?: boolean
  ): Promise<DocumentListResponse> {
    const response = await this.axios.get<DocumentListResponse>('document', {
      params: { page, order: tableToApiOrder(order), favorites },
      paramsSerializer: serializeParams,
    })

    return response.data
  }

  /**
   * Calls `POST /document` with the given document.
   *
   * @async
   * @param  document - document to be created
   * @throws if the user is not permitted to create documents
   * @returns the document's generated id of the
   */
  public async create({
    file,
    restrictAccess,
    relations,
  }: CreatableDocument): Promise<{
    id: string
  }> {
    try {
      const fileId = await this.fileApiService.upload(file)
      const data: DocumentCreateRequest = {
        name: file.name,
        file: fileId,
        restrictAccess,
        relations,
      }

      return (await this.axios.post<{ id: string }>('document', data)).data
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status
        if (status === 401) {
          throw new NotAllowedException()
        }
      }

      throw e
    }
  }

  /**
   * Adapter for modules.
   * Exists for downwards compatibility.
   * You may use {@link #create} instead.
   *
   * Uses {@link #create} internally.
   *
   * @async
   * @param document - module document dto
   * @returns The document's ID
   */
  public moduleCreate({
    file,
    restrictedTo,
    restrictions,
    relations,
  }: CreatableModuleDocument): Promise<{ id: string }> {
    let restrictAccess: SharedAccess
    if (
      restrictedTo === DocumentAccessibility.EVERYONE ||
      restrictions === undefined
    ) {
      restrictAccess = {
        to: DocumentAccessibility.EVERYONE,
      }
    } else {
      restrictAccess = {
        to: restrictedTo,
        restrictTo: restrictions,
      }
    }

    return this.create({ file, restrictAccess, relations })
  }

  /**
   * Calls upon `DELETE /document/${id}` to delete the document
   *
   * @async
   * @param {string} id - of the document to be deleted
   * @returns {Promise<void>}
   */
  public async delete(id: string): Promise<void> {
    await this.axios.delete(`document/${id}`)
  }

  /**
   * Searches for either users or companies using `GET /document/search/users` or `GET /document/search/companies`
   *
   * @async
   * @param {string} query - The query for which to search
   * @param {'users' | 'companies'} type - Whether to search for users or companies
   * @throws {NotAllowedException} - If the user is not allowed to search
   * @returns {Promise<IdAndName[]>} The ids and names of the users or companies
   */
  public async search(
    query: string,
    type: 'users' | 'groups' | 'companies'
  ): Promise<IdAndName[]> {
    try {
      const response = await this.axios.get<IdAndName[]>(
        `/document/search/${type}`,
        {
          params: { search: query },
        }
      )
      return response.data
    } catch (e: unknown) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }

      throw e
    }
  }

  /**
   * Uses `PATCH /document/${id}` to update the document.
   * Picks values from `newDocument` which are different from `oldDocument`.
   *
   * @async
   * @param {UpdatableDocument} oldDocument - The original document
   * @param {UpdatableDocument} newDocument - The changed document
   * @param {string} id - The id of the document (which may not change)
   * @returns {Promise<void>}
   */
  public async update(
    oldDocument: UpdatableDocument,
    newDocument: UpdatableDocument,
    id: string
  ): Promise<void> {
    const changes = pick(
      newDocument,
      Object.keys(diff(oldDocument, newDocument))
    )

    if (Object.keys(changes).length === 0) {
      return
    }

    const response = await this.axios.patch(`document/${id}`, changes)

    return response.data
  }

  /**
   * Set document as favorite.
   *
   * @async
   * @param {string} id - The id of the document
   * @returns {Promise<void>}
   */
  public async setFavorite(id: string): Promise<void> {
    return await this.axios.post('document/favorite', { documentId: id })
  }

  /**
   * Remove document as favorite.
   *
   * @async
   * @param {string} id - The id of the document
   * @returns {Promise<void>}
   */
  public async removeFavorite(id: string): Promise<void> {
    return await this.axios.delete('document/favorite', {
      data: { documentId: id },
    })
  }
}
