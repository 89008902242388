var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.toggleShow(false); }),expression:"() => toggleShow(false)"}],staticClass:"p-flyout",class:{ 'p-flyout--left': _vm.position === 'left' }},[_c('div',{staticClass:"p-flyout__flyout",class:{ 'p-flyout__flyout--shown': _vm.shown }},[(_vm.headerText)?_c('div',{staticClass:"p-flyout__header",on:{"click":_vm.toggleShow}},[_c('span',{staticClass:"p-flyout__icon"},[_c('p-icon',{attrs:{"name":"chevron-up","width":"20"}})],1),_vm._v(" "),_c('span',{staticClass:"p-flyout__header-text"},[_vm._v(_vm._s(_vm.headerText))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.items),function(item){return [_c('p-flyout-submenu',{key:item.label,attrs:{"submenu":item.submenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var showSubmenu = ref.toggleShow;
var subShown = ref.shown;
return [_c(item.href ? 'a' : item.to ? 'nuxt-link' : 'button',{tag:"component",staticClass:"p-flyout__item",class:{
              'p-flyout__item--active':
                _vm.localePath(item.to) === _vm.route.path || subShown,
            },attrs:{"type":item.action ? 'button' : null,"to":item.to ? _vm.localePath(item.to) : null,"href":item.href,"target":item.target},on:{"click":function($event){item.action ? item.action() : showSubmenu()}}},[_c('span',{staticClass:"p-flyout__icon"},[_c('p-icon',{attrs:{"name":_vm.localePath(item.to) === _vm.route.path && item.activeIcon
                    ? item.activeIcon
                    : item.icon,"height":"20"}})],1),_vm._v(" "),_c('span',{staticClass:"p-flyout__label"},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]),_vm._v(" "),(item.submenu !== undefined && item.submenu.length > 0)?_c('span',{staticClass:"p-flyout__icon"},[_c('p-icon',{staticClass:"\n                  transition\n                  duration-500\n                  ease-out\n                  transform-gpu\n                  rotate-0\n                ",class:{ 'rotate-90': subShown },attrs:{"name":"chevron-right","height":"20"}})],1):_vm._e()])]}}],null,true)})]})],2),_vm._v(" "),_vm._t("default",null,{"toggleShow":_vm.toggleShow,"shown":_vm.shown})],2)}
var staticRenderFns = []

export { render, staticRenderFns }