import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { inject } from 'inversify'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import type { Vxm } from '~/types/portal'

export class UserPermissionsService extends PermissionService {
  public static USER_GROUP_PERMISSIONS = {
    READ: 1,
    READ_FOX: 2,
    READ_ALL: 4,
    EDIT_ROLES: 8,
    EDIT_ROLES_ALL: 16,
    READ_CLUSTER: 32,
    EDIT_ROLES_CLUSTER: 64,
    DELETE_USERS: 512,
  }

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm
  ) {
    super(auth, vxm, '6WG-0-', 2)
  }

  public isListAnythingAllowed(): boolean {
    const groupPermission =
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ |
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ_ALL |
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ_CLUSTER

    return this.hasPermission(groupPermission)
  }

  public isEditRolesAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      UserPermissionsService.USER_GROUP_PERMISSIONS.EDIT_ROLES,
      UserPermissionsService.USER_GROUP_PERMISSIONS.EDIT_ROLES_CLUSTER,
      UserPermissionsService.USER_GROUP_PERMISSIONS.EDIT_ROLES_ALL
    )
  }

  public isSearchForContactPersonsAllowed(): boolean {
    const groupPermission =
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ |
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ_FOX |
      UserPermissionsService.USER_GROUP_PERMISSIONS.READ_CLUSTER

    return this.hasPermission(groupPermission)
  }

  public isDeleteUserAllowed(userId: string): boolean {
    const groupPermission =
      UserPermissionsService.USER_GROUP_PERMISSIONS.DELETE_USERS

    if (userId === this.getUser().id) {
      return false
    }

    return this.hasPermission(groupPermission)
  }
}
