import { inject, injectable } from 'inversify'
import { AxiosInstance } from 'axios'
import { DesignConfigDto, DesignConfigInterface } from '~/types/DesignConfig'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'

@injectable()
export default class ConfigurationApiService {
  public constructor(
    @inject(SYMBOLS.Axios) private readonly axios: AxiosInstance
  ) {}

  public update(configuration: DesignConfigInterface): Promise<void> {
    if (Object.keys(configuration).length === 0) {
      return Promise.resolve()
    }

    const {
      primaryColor,
      secondaryColor,
      logo,
      loginLogo,
      portalBackground,
      loginBackground,
      loginLogoBgColor,
    } = configuration

    const body: DesignConfigDto = {}

    if (primaryColor !== undefined) {
      body.primaryColor = primaryColor.toArray()
    } else if ('primaryColor' in configuration) {
      body.primaryColor = null
    }

    if (secondaryColor !== undefined) {
      body.secondaryColor = secondaryColor.toArray()
    } else if ('secondaryColor' in configuration) {
      body.secondaryColor = null
    }

    if (logo !== undefined) {
      body.logo = logo
    } else if ('logo' in configuration) {
      body.logo = null
    }

    if (loginLogo !== undefined) {
      body.loginLogo = loginLogo
    } else if ('loginLogo' in configuration) {
      body.loginLogo = null
    }

    if (portalBackground !== undefined) {
      body.portalBackground = portalBackground
    } else if ('portalBackground' in configuration) {
      body.portalBackground = null
    }

    if (loginBackground !== undefined) {
      body.loginBackground = loginBackground
    } else if ('loginBackground' in configuration) {
      body.loginBackground = null
    }

    if (loginLogoBgColor !== undefined) {
      body.loginLogoBgColor = loginLogoBgColor.toArray()
    } else if ('loginLogoBgColor' in configuration) {
      body.loginLogoBgColor = null
    }

    return this.axios.patch('/configuration', body)
  }
}
