/* eslint-disable require-await */
import { createModule, mutation, action } from 'vuex-class-component'
const VuexModule = createModule({
  namespaced: 'loading',
  strict: false,
  target: 'nuxt',
})

export class LoadingStore extends VuexModule {
  loading = false

  @mutation
  SET_LOADING(loading: boolean): void {
    this.loading = loading
  }

  @action
  async startLoading(): Promise<void> {
    this.SET_LOADING(true)
  }

  @action
  async stopLoading(): Promise<void> {
    this.SET_LOADING(false)
  }
}
