import type { NavigationItem } from '~/src/composables/useNavigation'

type Typeable = Pick<NavigationItem, 'type'>
type TypeableType = NonNullable<Typeable['type']> | 'default'

interface GroupConfiguration {
  label?: string
  showDivider?: boolean
}

type GroupConfigurationMap = Record<TypeableType, GroupConfiguration>

interface NavigationItemGroup extends GroupConfiguration {
  items: Typeable[]
}

function groupNavigationItems(
  navigationItems: readonly Typeable[],
  configuration: GroupConfigurationMap
) {
  return navigationItems.reduce((acc, item) => {
    const type: TypeableType = item.type ?? 'default'
    return {
      ...acc,
      [type]: {
        ...configuration[type],
        label: configuration[type].label,
        showDivider: configuration[type].showDivider,
        items: [...(acc[type]?.items ?? []), item],
      },
    } as const
  }, {} as Record<TypeableType, NavigationItemGroup>)
}

export {
  groupNavigationItems,
  GroupConfiguration,
  GroupConfigurationMap,
  NavigationItemGroup,
}
