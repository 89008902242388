import { inject, injectable } from 'inversify'
import Allowable from './Allowable'
import { PermissionService } from '~/src/services/permissions/PermissionService'

@injectable()
export default class Permission implements Allowable {
  public mask: number
  public serviceId: string
  public groupId: number

  constructor(
    @inject(PermissionService) protected permissionService: PermissionService
  ) {
    this.mask = 0
    this.serviceId = ''
    this.groupId = 0
  }

  isAllowed(): boolean {
    return this.permissionService.hasPermission(
      this.mask,
      this.groupId,
      this.serviceId
    )
  }
}

export type PermissionFactory = (
  mask: number,
  serviceId: string,
  groupId: number
) => Permission
