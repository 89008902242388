import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { inject, injectable } from 'inversify'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import type { Vxm } from '~/types/portal'

@injectable()
export class RegistrationPermissionsService extends PermissionService {
  public static DEFAULT_GROUP_PERMISSIONS = {
    READ: 1,
    READ_ALL: 2,
    EDIT: 4,
    EDIT_ALL: 8,
  }

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm
  ) {
    super(auth, vxm, '5wg-1A', 1)
  }

  public isAnythingAllowed(): boolean {
    return this.getMask() > 0
  }

  public mayRead(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ |
        RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ_ALL
    )
  }

  public mayReadOwn(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ
    )
  }

  public mayReadAll(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ_ALL
    )
  }

  public mayEdit(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT |
        RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_ALL
    )
  }

  public mayEditOwn(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT
    )
  }

  public mayEditAll(): boolean {
    return this.hasPermission(
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_ALL
    )
  }

  public isReadAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ,
      0,
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ_ALL
    )
  }

  public isEditAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT,
      0,
      RegistrationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_ALL
    )
  }
}
