import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import ClickOutside from 'vue-click-outside'
import { focusable } from 'tabbable'

Vue.directive('focus', {
  inserted(el) {
    const focusableElements = focusable(el, {
      includeContainer: true,
      displayCheck: 'non-zero-area',
    })

    if (focusableElements.length > 0) {
      focusableElements[0].focus()
    }
  },
})

Vue.directive('observe-visibility', ObserveVisibility)
Vue.directive('click-outside', ClickOutside)
