
import Vue from 'vue'
import { PropType } from '@nuxtjs/composition-api'
import { NavigationItem } from '~/src/composables/useNavigation'

export default Vue.extend({
  name: 'MainNavigationGroup',
  props: {
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    dividerLabel: {
      type: String,
      default: '',
    },
  },
})
