import { Ref, unref } from '@nuxtjs/composition-api'
import { AxiosError, AxiosInstance } from 'axios'
import { injectable, unmanaged } from 'inversify'
import { ListResponse } from './types/ListResponse'
import { tableToApiOrder } from '~/helpers/tableToApiOrder'
import { Identifiable } from '~/src/models/Identifiable'
import { TableOrderBy } from '~/types/portal'
import serializeParams from '~/helpers/serializeParams'

export class NotAllowedException extends Error {
  constructor() {
    super('common.not_allowed')
  }
}

@injectable()
export default abstract class AbstractApiService<
  T extends Identifiable,
  O extends TableOrderBy
> {
  protected constructor(
    @unmanaged() protected axios: AxiosInstance,
    protected resourcePath: string
  ) {
    this.axios = axios
    this.resourcePath = resourcePath
  }

  async list(page = 1, order?: O): Promise<ListResponse<T[]>> {
    try {
      const response = await this.axios.get(`/${this.resourcePath}`, {
        params: { page, order: tableToApiOrder(order) },
        paramsSerializer: serializeParams,
      })

      return response.data
    } catch (e) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }
      throw e
    }
  }

  async create(entity: T | Ref<T>): Promise<void> {
    try {
      const response = await this.axios.post(
        `/${this.resourcePath}`,
        unref(entity)
      )
      return response.data
    } catch (e) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }
      throw e
    }
  }

  async update(entity: T | Ref<T>): Promise<void> {
    try {
      const response = await this.axios.put(
        `/${this.resourcePath}/${unref(entity).uuid}`,
        unref(entity)
      )
      return response.data
    } catch (e) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }
      throw e
    }
  }

  async remove(entity: Identifiable | Ref<Identifiable>): Promise<void> {
    try {
      const response = await this.axios.delete(
        `/${this.resourcePath}/${unref(entity).uuid}`
      )
      return response.data
    } catch (e) {
      if (
        (e as AxiosError)?.response?.status === 403 ||
        (e as AxiosError)?.response?.status === 401
      ) {
        throw new NotAllowedException()
      }
      throw e
    }
  }
}
