import Address from '~/src/models/Address'

export interface CompanyLocationConstructor {
  uuid: string
  name: string
  address: Address
}

export default class CompanyLocation {
  public uuid: string
  public name: string
  public address: Address

  constructor({
    uuid = '',
    name = '',
    address,
  }: Partial<CompanyLocationConstructor>) {
    this.uuid = uuid
    this.name = name
    this.address = new Address(address ? { ...address } : {})
  }
}
