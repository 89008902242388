import { AxiosRequestConfig } from 'axios'
import merge from 'deepmerge'
import { assert, integer, min, Struct } from 'superstruct'

export default class LimitDecorator {
  private positiveInteger: Struct<number, null>
  constructor() {
    this.positiveInteger = min(integer(), 1)
  }

  decorate(limit = 10, config?: AxiosRequestConfig): AxiosRequestConfig {
    assert(limit, this.positiveInteger)

    const limitConfig: AxiosRequestConfig = { params: { limit } }

    return merge(config ?? {}, limitConfig)
  }
}
