import { render, staticRenderFns } from "./PMainNavigation.vue?vue&type=template&id=122a470b&"
import script from "./PMainNavigation.vue?vue&type=script&lang=ts&"
export * from "./PMainNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./PMainNavigation.vue?vue&type=style&index=0&id=122a470b&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNavigationGroup: require('/app/components/navigation/MainNavigationGroup.vue').default,PIcon: require('/app/components/PIcon.vue').default})
