import Vue from 'vue'
import VueGTag from 'vue-gtag'
import type { Plugin } from '@nuxt/types'
import { useVxm } from '~/src/composables/dependency'

const plugin: Plugin = (ctx) => {
  const vxm = useVxm()
  const portalConfig = vxm.portalConfig.config
  const pluginConfig = portalConfig?.tracking.googleAnalytics?.config

  if (pluginConfig) {
    Vue.use(
      VueGTag,
      {
        config: {
          id: pluginConfig.trackingId,
        },
        enabled: pluginConfig.enabled,
      },
      ctx.app.router
    )
  }
}

export default plugin
