



































































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PModal',
  inheritAttrs: false,
  model: {
    prop: 'visible',
    event: 'input',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    noCancel: {
      type: Boolean,
      default: false,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
    disableAccept: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: () => 'common.cancel',
    },
    acceptLabel: {
      type: String,
      default: () => 'common.ok',
    },
    focusTrap: {
      type: Boolean,
      default: true,
    },
    focusRetain: {
      type: Boolean,
      default: true,
    },
    acceptTestid: {
      type: String,
      default: undefined,
    },
    form: {
      type: String,
      default: null,
    },
  },
  setup(_, { emit }) {
    const baseTransition =
      'transition duration-700 ease-out-quint transform-gpu'
    const transition = {
      'enter-active-class': baseTransition,
      'enter-class': 'opacity-0 translate-x-[685px] pointer-events-none',
      'enter-to-class': 'opacity-100 translate-x-0 pointer-events-none',
      'leave-active-class': baseTransition,
      'leave-class': 'opacity-100 translate-x-0 pointer-events-none',
      'leave-to-class': 'opacity-0 translate-x-[685px] pointer-events-none',
    }

    const overlayTransition = {
      'enter-active-class': baseTransition,
      'enter-class': 'opacity-0',
      'enter-to-class': 'opacity-100',
      'leave-active-class': baseTransition,
      'leave-to-class': 'opacity-0',
      'leave-class': 'opacity-100',
    }

    const onClose = () => {
      emit('closed')
    }

    return { transition, overlayTransition, onClose }
  },
})
