import axios, { AxiosError, AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import SYMBOLS from '../dependency_injection/Symbols'
import Company from '../models/Company'
import { AddressConstructor } from './../models/Address'
import { BaseUser } from './UserApiService'
import { tableToApiOrder } from '~/helpers/tableToApiOrder'
import {
  ConstraintsViolatedError,
  ConstraintViolation,
} from '~/src/models/ConstraintsViolatedError'
import { TableOrderBy } from '~/types/portal'
import serializeParams from '~/helpers/serializeParams'

export class RegistrationId {
  private value: string

  public static fromString(value: string): RegistrationId {
    return new RegistrationId(value)
  }

  public toString(): string {
    return this.value
  }

  private constructor(value: string) {
    this.value = value
  }
}

type CompanyId = Company['uuid']

export interface CompanyNameAndAddress {
  name: string
  address: AddressConstructor
}

export interface CompanyNameAndId {
  name: string
  id: CompanyId
}

export class RegistrationNotFoundError extends Error {}
export class RegistrationNotPendingError extends Error {}

export class ConfirmationTokenNotFoundError extends Error {}

export enum State {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export type RegistrationRequestCompanyData =
  | {
      discriminator: 'id'
      id: CompanyId
    }
  | ({ discriminator: 'data' } & CompanyNameAndAddress)

export type RegistrationListCompanyDiscriminator = 'id' | 'address'

export interface RegistrationListCompany {
  name: string
  id?: CompanyId
  address?: AddressConstructor
}

export type RegistrationListItem = {
  id: RegistrationId
  company: RegistrationListCompany
  email: string
  state: State
  creation: Date
  user?: BaseUser
}

export type RegistrationList = {
  pages: number
  result: Array<RegistrationListItem>
}

export type RegistrationListResponseCompanyData =
  | ({ discriminator: 'id' } & CompanyNameAndId)
  | ({ discriminator: 'address' } & CompanyNameAndAddress)

export type RegistrationListResponseCompany = {
  name: string
  data: RegistrationListResponseCompanyData
}

type RegistrationListResponseItem = {
  id: string
  email: string
  state: State
  creation: string
  company: RegistrationListResponseCompany
  user: BaseUser
}

type RegistrationListResponse = {
  pages: number
  result: Array<RegistrationListResponseItem>
}

type CreatedResponseBody = {
  id: string
}

type UnprocessableEntityResponseBody = Array<ConstraintViolation>

export type RegistrationSortingKeys = 'company' | 'email' | 'creation'

interface RegistrationRequest {
  company: RegistrationRequestCompanyData
  email: string
  password: string
  user?: Partial<BaseUser>
}

@injectable()
export default class RegistrationApiService {
  public constructor(@inject(SYMBOLS.Axios) private axios: AxiosInstance) {}

  public async request(
    companyId: CompanyId,
    email: string,
    password: string,
    user?: BaseUser
  ): Promise<RegistrationId>

  public async request(
    companyNameAndAddress: CompanyNameAndAddress,
    email: string,
    password: string,
    user?: BaseUser
  ): Promise<RegistrationId>

  public async request(
    companyData: CompanyId | CompanyNameAndAddress,
    email: string,
    password: string,
    user: BaseUser | null = null
  ): Promise<RegistrationId> {
    try {
      let company: RegistrationRequestCompanyData

      if (typeof companyData === 'string') {
        company = {
          discriminator: 'id',
          id: companyData,
        }
      } else {
        company = {
          discriminator: 'data',
          ...companyData,
        }
      }

      const request: RegistrationRequest = {
        company,
        email,
        password,
      }

      if (user !== null) {
        request.user = Object.fromEntries(
          Object.entries(user).filter(([_, value]) => value !== null)
        )
      }

      const response = await this.axios.post<CreatedResponseBody>(
        '/registration',
        request
      )
      return RegistrationId.fromString(response.data.id)
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 422) {
        throw new ConstraintsViolatedError(
          (e as AxiosError).response?.data as UnprocessableEntityResponseBody
        )
      }

      throw e
    }
  }

  public async accept(
    registration: RegistrationId,
    company: CompanyId
  ): Promise<void> {
    try {
      await this.axios.post(
        '/registration/' + registration.toString() + '/accept',
        {
          company,
        }
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e?.response?.status

        if (status === 404) {
          throw new RegistrationNotFoundError()
        }

        if (status === 409) {
          throw new RegistrationNotPendingError()
        }
      }

      throw e
    }
  }

  public async reject(registration: RegistrationId): Promise<void> {
    try {
      await this.axios.post(
        '/registration/' + registration.toString() + '/reject'
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status
        if (status === 404) {
          return Promise.reject(new RegistrationNotFoundError())
        }

        if (status === 409) {
          return Promise.reject(new RegistrationNotPendingError())
        }
      }

      return Promise.reject(e)
    }
  }

  public async list(
    states: State[] = [State.PENDING],
    page = 1,
    order: TableOrderBy<RegistrationSortingKeys> | undefined = undefined
  ): Promise<RegistrationList> {
    if (states.length === 0) {
      states = [State.PENDING]
    }

    let defaultTransformers = this.axios.defaults.transformResponse ?? []

    if (!Array.isArray(defaultTransformers)) {
      defaultTransformers = [defaultTransformers]
    }

    const stateSet = new Set(states)
    const response = await this.axios.get<RegistrationList>('/registration', {
      params: {
        state: stateSet,
        page,
        order: tableToApiOrder(order),
      },
      responseType: 'json',
      paramsSerializer: serializeParams,
      transformResponse: defaultTransformers.concat(
        (data: RegistrationListResponse) => {
          return {
            pages: data.pages,
            result: data.result.map(
              (item: RegistrationListResponseItem): RegistrationListItem => ({
                id: RegistrationId.fromString(item.id),
                email: item.email,
                creation: new Date(item.creation),
                state: item.state,
                company: {
                  id:
                    item.company.data.discriminator === 'id'
                      ? item.company.data.id
                      : undefined,
                  name: item.company.name,
                  address:
                    item.company.data.discriminator === 'address'
                      ? item.company.data.address
                      : undefined,
                },
                user: item.user,
              })
            ),
          }
        }
      ),
    })

    return response.data
  }

  public async confirm(token: string): Promise<void> {
    try {
      await this.axios.post(`/registration/confirm-email-address/${token}`)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        throw new ConfirmationTokenNotFoundError()
      }

      throw e
    }
  }
}
