import type { LocaleObject } from 'nuxt-i18n'

export function isStringArray<T>(a: string[] | T[]): a is string[] {
  return typeof a[0] === 'string'
}

export function assertLocalObjectArray(
  locales: LocaleObject[] | string[]
): asserts locales is LocaleObject[] {
  if (isStringArray(locales)) {
    throw new Error('Expected LocaleObject[]')
  }
}
