import matchBreadcrumb from '~/helpers/breadcrumb/matchBreadcrumb'
import type { BreadcrumbConfigRecord, BreadcrumbItem } from '~/types/portal'

export default function createBreadcrumb(
  path: string,
  list: BreadcrumbConfigRecord,
  routePrefix = '/'
): BreadcrumbItem[] {
  const items: BreadcrumbItem[] = []

  const [match, restPath] = matchBreadcrumb(path, list)

  if (match === undefined) {
    return items
  }

  const [matchKey, matchValue] = match
  let label: string

  if (typeof matchValue === 'string') {
    label = matchValue
  } else {
    label = matchValue.label
  }

  items.push({ location: routePrefix + matchKey, label })

  if (
    `${routePrefix}${matchKey}` !== path &&
    typeof matchValue !== 'string' &&
    matchValue.children
  ) {
    let prefix = routePrefix + matchKey
    if (!prefix.endsWith('/')) {
      prefix += '/'
    }

    items.push(...createBreadcrumb(restPath, matchValue.children, prefix))
  }

  return items
}
