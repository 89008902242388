
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PMainNavigationLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
})
