import Vue from 'vue'
import { VueQueryPlugin, QueryClient, hydrate } from 'vue-query'
import type { Plugin } from '@nuxt/types'

const plugin: Plugin = (context) => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 } },
  })
  const options = { queryClient }

  Vue.use(VueQueryPlugin, options)

  if (process.client) {
    if (context.nuxtState && context.nuxtState['vue-query']) {
      hydrate(queryClient, context.nuxtState['vue-query'])
    }
  }
}

export default plugin
