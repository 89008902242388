import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { inject, injectable } from 'inversify'
import Diffable from './types/Diffable'
import LimitDecorator from '~/src/services/types/LimitDecorator'
import { ListResponse } from '~/src/services/types/ListResponse'
import PageDecorator from '~/src/services/types/PageDecorator'
import ShallowDiffer from '~/src/services/types/ShallowDiffer'
import { Dictionary } from '~/types/dictionary'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'

export type DataPoolId = string
export type DataPoolPropertyType = 'string' | 'integer' | 'date-time'
export type DataPoolHeaderKey = string
export interface DataPoolConfigurationItem {
  propertyName: string
  propertyType: DataPoolPropertyType
}

export type DataPoolMapping = Dictionary<
  DataPoolHeaderKey,
  DataPoolPropertyType
>
export interface DataPoolDefinition {
  title: string
}

export type DataPoolObject = Dictionary<DataPoolHeaderKey, string>
export interface DataPoolData {
  definition: Dictionary<DataPoolHeaderKey, DataPoolDefinition>
  objects?: Array<DataPoolObject>
}
export type DataPoolReadData = Pick<DataPoolData, 'definition'>
export type DataPoolWriteData = Required<DataPoolData>
export interface DataPool<
  Data extends
    | DataPoolData
    | DataPoolReadData
    | DataPoolWriteData = DataPoolData
> {
  id?: DataPoolId
  title: string
  description: string
  lastChange?: string
  data?: Data
  mapping?: DataPoolMapping
}

export type DataPoolListItem = Required<
  Pick<DataPool, 'id' | 'title' | 'description' | 'lastChange'>
>

export type DataPoolById = Required<
  Pick<DataPool<DataPoolReadData>, 'title' | 'description' | 'data' | 'mapping'>
>

export type DataPoolToCreate = Required<
  Pick<
    DataPool<DataPoolWriteData>,
    'title' | 'description' | 'data' | 'mapping'
  >
>

export type DataPoolToUpdate = Partial<DataPoolToCreate>

export type DataPoolDiff = Omit<DataPoolToUpdate, 'mapping'> & {
  mapping?: [DataPoolHeaderKey, DataPoolPropertyType | undefined][]
}

export type DataPoolContent = Dictionary<string, DataPoolObject>

@injectable()
export default class DataPoolApiService {
  private diffBehavior: Diffable<DataPoolDiff>
  private pageDecorator: PageDecorator
  private limitDecorator: LimitDecorator

  public static readonly url = '/data-pool'

  public constructor(
    @inject(SYMBOLS.Axios) private readonly axios: AxiosInstance
  ) {
    this.pageDecorator = new PageDecorator()
    this.limitDecorator = new LimitDecorator()
    this.diffBehavior = new ShallowDiffer<DataPoolDiff>()
  }

  public async list(): Promise<ListResponse<DataPoolListItem[]>>
  public async list(page: number): Promise<ListResponse<DataPoolListItem[]>>
  public async list(
    page: number,
    limit: number
  ): Promise<ListResponse<DataPoolListItem[]>>

  public async list(
    page?: number,
    limit?: number
  ): Promise<ListResponse<DataPoolListItem[]>> {
    let config = {}
    config = this.pageDecorator.decorate(page, config)
    config = this.limitDecorator.decorate(limit, config)

    const result = await this.axios.get(DataPoolApiService.url, config)

    return result.data
  }

  public async getById(id: DataPoolId): Promise<DataPoolById> {
    const response = await this.axios.get(`${DataPoolApiService.url}/${id}`)
    return response.data
  }

  public getContent(id: DataPoolId): Promise<ListResponse<DataPoolContent>>
  public getContent(
    id: DataPoolId,
    page: number
  ): Promise<ListResponse<DataPoolContent>>

  public async getContent(
    id: DataPoolId,
    page: number,
    rowIndex: string
  ): Promise<ListResponse<DataPoolContent>>

  public async getContent(
    id: DataPoolId,
    page?: number,
    rowIndex?: string
  ): Promise<ListResponse<DataPoolContent>> {
    let config: AxiosRequestConfig = {
      params: {
        rowIndex,
      },
    }
    config = this.pageDecorator.decorate(page, config)

    const response = await this.axios.get(
      `${DataPoolApiService.url}/${id}/content`,
      config
    )
    return response.data
  }

  public async create(dataPool: DataPoolToCreate): Promise<DataPoolId> {
    const response = await this.axios.post(DataPoolApiService.url, dataPool)
    return response.data
  }

  public async update(
    id: DataPoolId,
    newDataPool: DataPoolToUpdate
  ): Promise<void>

  public async update(
    id: DataPoolId,
    newDataPool: DataPoolToUpdate,
    oldDataPool: DataPoolToUpdate
  ): Promise<void>

  public async update(
    id: DataPoolId,
    newDataPool: DataPoolToUpdate,
    oldDataPool?: DataPoolToUpdate
  ): Promise<void> {
    const a: DataPoolDiff = {
      ...newDataPool,
      mapping: newDataPool.mapping
        ? Object.entries(newDataPool.mapping)
        : undefined,
    }

    const b: DataPoolDiff | undefined = oldDataPool
      ? {
          ...oldDataPool,
          mapping: oldDataPool.mapping
            ? Object.entries(oldDataPool.mapping)
            : undefined,
        }
      : undefined

    const diff = this.diffBehavior.diff(a, b)

    if (diff === undefined) {
      return
    }

    const dto: DataPoolToUpdate = {
      ...diff,
      mapping: diff.mapping ? Object.fromEntries(diff.mapping) : undefined,
    }

    await this.axios.patch(`${DataPoolApiService.url}/${id}`, dto)
  }
}
