import { BreadcrumbConfigRecord, BreadcrumbConfigItem } from '~/types/portal'

type Match = [string, BreadcrumbConfigItem] | undefined
type MatchedBreadcrumb = [Match, string]

const matchBreadcrumb = (
  path: string,
  config: BreadcrumbConfigRecord
): MatchedBreadcrumb => {
  const pathParts = path.split('?')[0].split('/')
  let match: Match
  let maxMatchCount = 0

  Object.entries(config).forEach(([key, value]) => {
    const entryParts = `${key}`.split('/')
    let matchCount = 0

    if (entryParts.length > pathParts.length) {
      return
    }

    entryParts?.forEach((match, index) => {
      if (pathParts?.[index] === match) {
        matchCount++
      }
    })

    if (matchCount > maxMatchCount) {
      maxMatchCount = matchCount
      match = [key, value]
    }
  })

  let restPath = ''

  if (maxMatchCount < pathParts.length) {
    restPath = pathParts.splice(maxMatchCount).join('/')
  }

  return [match, restPath]
}

export default matchBreadcrumb
