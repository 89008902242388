import { inject, injectable } from 'inversify'
import type { PermissionFactory } from '~/src/services/permissions/types/Permission'
import SYMBOLS from '~/src/dependency_injection/Symbols'

@injectable()
export class NavPermissionsService {
  public constructor(
    @inject(SYMBOLS.NavPermissionService)
    private readonly serviceId: string,
    @inject(SYMBOLS.StaticNavPermissionService)
    private readonly staticServiceId: string,
    @inject(SYMBOLS.PermissionFactory)
    private permissionFactory: PermissionFactory
  ) {}

  public generateNavPermission(permission: number, group = 1) {
    return this.permissionFactory(permission, this.serviceId, group)
  }

  public generateStaticNavPermission(permission: number, group = 1) {
    return this.permissionFactory(permission, this.staticServiceId, group)
  }
}
