












import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PStatusDot',
  functional: true,
  props: {
    status: {
      type: String,
      default: 'online',
    },
  },
})
