
import { computed, watch, nextTick } from '@nuxtjs/composition-api';
import { onMounted, onUnmounted, ref, Ref, watchEffect } from '@vue/composition-api';
import { debounce } from 'lodash';
import type VueComponent from 'vue';
import type { BreadcrumbItem as BreadcrumbItemType } from '~/types/portal';
interface Props {
  items: BreadcrumbItemType[];
}
const __sfc_main = {};
__sfc_main.props = {
  items: {
    key: "items",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const scrollIndex: Ref<number> = ref(0);
  const containerRef: Ref<HTMLElement | null> = ref(null);
  const itemsRef: Ref<VueComponent[]> = ref([]);
  const showArrows: Ref<boolean> = ref(false);
  const maxScroll = computed(() => itemsRef.value.length - 1);
  const scrolledToEnd = computed(() => scrollIndex.value === maxScroll.value);
  const scrolledToStart = computed(() => scrollIndex.value === 0);
  const resizeObserver = new ResizeObserver(debounce<ResizeObserverCallback>(entries => {
    if (entries.length === 0) {
      return;
    }
    setShowArrows(entries[0].target);
  }, 250));
  watch(maxScroll, newMaxScroll => {
    scrollIndex.value = newMaxScroll;
  });
  watchEffect(() => {
    if (maxScroll.value > 0) {
      scrollToIndex(scrollIndex.value);
    }
  });
  watch([maxScroll, scrollIndex], ([newMaxScroll, newScrollIndex]) => {
    if (newMaxScroll > 0) {
      scrollToIndex(newScrollIndex);
    }
  });
  onMounted(() => {
    if (containerRef.value != null) {
      resizeObserver.observe(containerRef.value);
    }
  });
  onUnmounted(() => {
    resizeObserver.disconnect();
  });
  function setShowArrows(element: Element) {
    showArrows.value = Math.round(element.scrollWidth) > Math.round(element.clientWidth);
  }
  function scrollLeft() {
    if (containerRef.value == null) {
      return;
    }
    scrollIndex.value = Math.max(0, scrollIndex.value - 1);
  }
  function scrollRight() {
    scrollIndex.value = Math.min(maxScroll.value, scrollIndex.value + 1);
  }
  async function scrollToIndex(index: number) {
    if (containerRef.value == null || index < 0 || itemsRef.value[index] == null) {
      return;
    }
    setShowArrows(containerRef.value);
    await nextTick();
    containerRef.value.scrollBy({
      left: itemsRef.value[index].$el.getBoundingClientRect().x - containerRef.value.getBoundingClientRect().x,
      behavior: 'smooth'
    });
  }
  return {
    containerRef,
    itemsRef,
    showArrows,
    scrolledToEnd,
    scrolledToStart,
    scrollLeft,
    scrollRight
  };
};
export default __sfc_main;
