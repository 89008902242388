import { useContext } from '@nuxtjs/composition-api'
// eslint-disable-next-line import/named
import { NuxtI18nInstance } from 'nuxt-i18n'

export function useI18n(): NuxtI18nInstance {
  const { i18n } = useContext()

  if (!i18n) {
    throw new Error('i18n is not defined!')
  }
  return i18n
}

export function useLocaleRoute() {
  const { localeRoute } = useContext()

  if (!localeRoute) {
    throw new Error('localeRoute is not defined!')
  }
  return localeRoute
}

export function useLocalePath() {
  const { localePath } = useContext()

  if (!localePath) {
    throw new Error('localePath is not defined!')
  }
  return localePath
}
