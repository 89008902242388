
import { computed, watch } from '@nuxtjs/composition-api'
import { defineComponent, ref } from '@vue/composition-api'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useVxm } from '~/src/composables/dependency'

export default defineComponent({
  name: 'PLoadingOverlay',
  setup(_props, { root: { $vfm } }) {
    const overlay = ref<HTMLElement | null>(null)
    const vxm = useVxm()
    const loading = computed(() => vxm.loading.loading)
    const fixed = ref<boolean>(false)

    watch(loading, (isLoading) => {
      fixed.value = !!$vfm.openedModals.length

      if (isLoading && overlay.value) {
        disableBodyScroll(overlay.value)
      } else {
        clearAllBodyScrollLocks()
      }
    })

    return { loading, overlay, fixed }
  },
})
