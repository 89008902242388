
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useVxm } from '~/src/composables/dependency'

export default defineComponent({
  name: 'GuestLayout',
  setup() {
    const vxm = useVxm()
    const configuration = computed(() => vxm.configuration.configuration)
    const background = computed(() => configuration.value?.loginBackground)
    const year = new Date().getFullYear()

    const fuchsbauName = vxm.portalConfig.fuchsbauName

    return { configuration, background, year, fuchsbauName }
  },
})
