import { extractVuexModule } from 'vuex-class-component'
import { BreadcrumbStore } from '~/store/breadcrumb'
import { ConfigurationStore } from '~/store/configuration'
import { LoadingStore } from '~/store/loading'
import { PermissionStore } from '~/store/permissions'
import { PortalConfigStore } from '~/store/portalConfig'
import { RoleStore } from '~/store/roles'
import { AccountAttributesStore } from '~/store/accountAttributes'
import { UserStore } from '~/store/users'
import { NavigationStore } from '~/store/navigation'
import { CompanyStore } from '~/store/companies'

export const modules = {
  ...extractVuexModule(RoleStore),
  ...extractVuexModule(UserStore),
  ...extractVuexModule(PermissionStore),
  ...extractVuexModule(ConfigurationStore),
  ...extractVuexModule(BreadcrumbStore),
  ...extractVuexModule(LoadingStore),
  ...extractVuexModule(AccountAttributesStore),
  ...extractVuexModule(PortalConfigStore),
  ...extractVuexModule(NavigationStore),
  ...extractVuexModule(CompanyStore),
}
