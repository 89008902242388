// @ts-nocheck

import { decode } from 'jsonwebtoken'
import { HTTPResponse } from '@nuxtjs/auth-next'
// @ts-ignore
import { OpenIDConnectScheme } from '~auth/runtime'
import { transformTokenData } from '~/schemes/auth/common'
import 'navigator.locks'

export default class Oidc extends OpenIDConnectScheme {
  updateTokens(response: unknown) {
    super.updateTokens(response)
    this.setUser()
  }

  async refreshTokens(): Promise<HTTPResponse | void> {
    return await this.lock(async () => await super.refreshTokens())
  }

  mounted() {
    const { $authUrl } = this.$auth.ctx

    this.options.endpoints.configuration = `${$authUrl}/auth/realms/kundenportal/.well-known/openid-configuration`

    return super.mounted()
  }

  lock(
    callback: () => Promise<HTTPResponse | void>
  ): Promise<HTTPResponse | void> {
    if (!process.client) {
      return Promise.resolve()
    }
    return window.navigator.locks.request('auth-refresh', callback)
  }

  fetchUser() {
    // @ts-ignore
    if (!this.check().valid) {
      return Promise.resolve()
    }

    this.setUser()

    return Promise.resolve()
  }

  setUser() {
    const token = this.getDecodedToken()

    // @ts-ignore
    this.$auth.setUser(transformTokenData(token))
  }

  getDecodedToken() {
    // @ts-ignore
    return decode(this.token.get()?.replace(/^Bearer /, ''))
  }
}
