import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { inject, injectable } from 'inversify'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import type { Vxm } from '~/types/portal'

@injectable()
export class ConfigurationPermissionsService extends PermissionService {
  public static DEFAULT_GROUP_PERMISSIONS = {
    EDIT: 1,
  }

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm
  ) {
    super(auth, vxm, 'Q4O9Bn', 1)
  }

  public isEditAllowed(): boolean {
    return this.hasPermission(
      ConfigurationPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT
    )
  }
}
