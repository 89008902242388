export const SYMBOLS = {
  Axios: Symbol('axios'),
  Auth: Symbol('auth'),
  FuchsbauId: Symbol('fuchsbauId'),
  Translator: Symbol('translator'),
  Vxm: Symbol('vxm'),
  NavPermissionService: Symbol('NavPermissionService'),
  StaticNavPermissionService: Symbol('StaticNavPermissionService'),
  PermissionFactory: Symbol('PermissionFactory'),
  DifferentiatingPermissionFactory: Symbol('DifferentiatingPermissionFactory'),
}

export default SYMBOLS
