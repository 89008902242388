import qs from 'qs'
import { decode } from 'jsonwebtoken'
import {
  onLogin,
  onSetUserData,
  transformTokenData,
} from '@/schemes/auth/common'
import { RefreshScheme } from '~auth/runtime'
import 'navigator.locks'

export default class Keycloak extends RefreshScheme {
  constructor($auth, options) {
    const fn = function (data) {
      return qs.stringify(data)
    }
    options.endpoints.refresh.transformRequest = [fn]
    options.endpoints.login.transformRequest = [fn]
    super($auth, options)

    this.promisedRefresh = null
  }

  updateTokens(
    response,
    { isRefreshing = false, updateOnRefresh = true } = {}
  ) {
    super.updateTokens(response, { isRefreshing, updateOnRefresh })

    this.setUser()
  }

  refreshTokens() {
    return window.navigator.locks.request('auth-refresh', async () => {
      if (this.promisedRefresh !== null) {
        return this.promisedRefresh
      }

      this.promisedRefresh = super.refreshTokens()
      const result = await this.promisedRefresh
      this.promisedRefresh = null

      return result
    })
  }

  mounted() {
    const { $authUrl } = this.$auth.ctx

    this.options.endpoints.login.url = `${$authUrl}/auth/realms/kundenportal/protocol/openid-connect/token`
    this.options.endpoints.refresh.url = `${$authUrl}/auth/realms/kundenportal/protocol/openid-connect/token`
    this.options.endpoints.logout.url = `${$authUrl}/auth/realms/kundenportal/account/sessions/`

    return super.mounted()
  }

  fetchUser() {
    if (!this.check().valid) {
      return Promise.resolve()
    }

    this.setUser()

    return Promise.resolve()
  }

  async logout(endpoint) {
    if (typeof endpoint === 'undefined') {
      endpoint = {}
    }
    endpoint.url =
      this.options.endpoints.logout.url + this.getFromToken('session_state')
    await super.logout(endpoint)
  }

  setUser() {
    const token = this.getDecodedToken()

    this.$auth.setUser(transformTokenData(token))
  }

  getFromToken(field) {
    const token = this.getDecodedToken()
    return token[field]
  }

  getDecodedToken() {
    return decode(this.token.get()?.replace(/^Bearer /, ''))
  }
}
