import { inject, injectable } from 'inversify'
import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'
import type { Vxm } from '~/types/portal'

@injectable()
export class CompanyPermissionsService extends PermissionService {
  public static DEFAULT_GROUP_PERMISSIONS = {
    READ: 1,
    READ_ALL: 2,
    EDIT: 4,
    EDIT_ALL: 8,
    CREATE: 16,
    DELETE_ALL: 32,
    INVITE: 64,
    INVITE_ALL: 128,
  }

  public static CONTACT_GROUP = 2
  public static CONTACT_GROUP_PERMISSIONS = {
    READ: 1,
  }

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm
  ) {
    super(auth, vxm, 'IRFa-V', 1)
  }

  public isCreateNewAllowed(): boolean {
    return (
      (this.getMask() &
        CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.CREATE) >
      0
    )
  }

  public isReadAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ,
      0,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.READ_ALL
    )
  }

  public isEditAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT,
      0,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT_ALL
    )
  }

  public isDeleteCompanyAllowed(companyId: string): boolean {
    if (companyId === this.getUser().company) {
      return false
    }

    return this.isAllowed(
      companyId,
      0,
      0,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.DELETE_ALL
    )
  }

  public isInviteAllowed(companyId: string): boolean {
    return this.isAllowed(
      companyId,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.INVITE,
      0,
      CompanyPermissionsService.DEFAULT_GROUP_PERMISSIONS.INVITE_ALL
    )
  }

  public isReadContactPersonsAllowed(): boolean {
    return (
      (this.getMask(
        this.serviceId ?? 'IRFa-V',
        CompanyPermissionsService.CONTACT_GROUP
      ) &
        CompanyPermissionsService.CONTACT_GROUP_PERMISSIONS.READ) >
      0
    )
  }
}
