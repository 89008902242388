import { render, staticRenderFns } from "./PTopNavigation.vue?vue&type=template&id=83921fa4&"
import script from "./PTopNavigation.vue?vue&type=script&lang=ts&"
export * from "./PTopNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./PTopNavigation.vue?vue&type=style&index=0&id=83921fa4&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PIcon: require('/app/components/PIcon.vue').default,PLogo: require('/app/components/PLogo.vue').default,PAvatar: require('/app/components/PAvatar.vue').default,PStatusDot: require('/app/components/PStatusDot.vue').default,PFlyout: require('/app/components/PFlyout.vue').default})
